import * as t from 'io-ts'

import { SimpleReason } from './SimpleReason'
import { ComplexReason } from './ComplexReason'

export type FailedNodeReason = SimpleReason | ComplexReason

export namespace FailedNodeReason {
  // eslint-disable-next-line prefer-destructuring
  export const io: t.Type<FailedNodeReason> = t.union([
    SimpleReason.io,
    ComplexReason.io,
  ])
}
