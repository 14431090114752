import React from 'react'
import { IconButton, Trash, Tooltip, Button } from '@gmini/ui-kit'

import {
  Container,
  CloseButtonWrap,
  Content,
  FirstLine,
  WarningContainer,
  WarningActions,
  WarningDeleteText,
  Popover,
} from './ConditionLayout.styled'

type ConditionLayoutProps = {
  onRemove: () => void
  formulaInput: React.ReactNode
  nameInput: React.ReactNode
  noteText: React.ReactNode
  disabled: boolean
}

export const ConditionLayout = React.memo<ConditionLayoutProps>(
  ({ formulaInput, onRemove, nameInput, disabled, noteText }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
      null,
    )

    const handleClick = React.useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
      },
      [],
    )

    const handleClose = React.useCallback(() => {
      setAnchorEl(null)
    }, [])

    const onRemoveClick = React.useCallback(() => {
      onRemove()
      setAnchorEl(null)
    }, [onRemove])

    const open = Boolean(anchorEl)

    return (
      <Container>
        <Content>
          <FirstLine>{nameInput}</FirstLine>
          {formulaInput}
          {noteText}
        </Content>
        <div>
          <CloseButtonWrap>
            <Tooltip placement='top' title='Удалить вычислимую колонку'>
              <IconButton
                onClick={handleClick}
                disabled={disabled}
                type='circle'
              >
                <Trash
                  color='rgba(53, 59, 96, 0.25)'
                  width='24px'
                  height='24px'
                />
              </IconButton>
            </Tooltip>

            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <WarningContainer>
                <WarningDeleteText>
                  Вы действительно хотите удалить колонку?
                </WarningDeleteText>
                <WarningActions>
                  <Button
                    color='warning'
                    disabled={disabled}
                    onClick={onRemoveClick}
                    size='regular'
                  >
                    Удалить
                  </Button>
                  <Button
                    size='regular'
                    color='secondary'
                    onClick={handleClose}
                  >
                    Отмена
                  </Button>
                </WarningActions>
              </WarningContainer>
            </Popover>
          </CloseButtonWrap>
        </div>
      </Container>
    )
  },
)

ConditionLayout.displayName = 'ConditionLayout'
