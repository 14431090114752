import * as t from 'io-ts'

import { AbstractFailedNode } from './AbstractFailedNode'

export interface ChildrenFailedNode extends AbstractFailedNode {
  type: 'ChildrenFailedNode'
}

export namespace ChildrenFailedNode {
  export const io: t.Type<ChildrenFailedNode> = t.intersection(
    [
      AbstractFailedNode.io,
      t.type({
        type: t.literal('ChildrenFailedNode'),
      }),
    ],
    'ChildrenFailedNode',
  )
}
