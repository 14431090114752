import { prop } from 'ramda'

import { ExternalEvent } from '../../checkups-service'

import * as api from '../../api'

import { notificationService } from '../../services/notificationService'

export const created = notificationService.message
  .filter({ fn: ExternalEvent.Create.is })
  .map(prop('payload'))

export const folderCreated = created.filter({ fn: api.CheckupRepoFolder.is })
export const checkupCreated = created.filter({ fn: api.Checkup.is })
