import * as React from 'react'
import { useStore, useStoreMap } from 'effector-react'

import { CheckupsService, buildCheckupStatusKey } from '../../checkups-service'

import * as api from '../../api'

export interface UseCheckupValue {
  checkupStatus: api.Checkup.Status | null
  xlsxReportUrl: string
  pending: boolean
  error: boolean
  finished: boolean
  emptyCondition: boolean
  checkup: {
    checkupId: number
    checkupVersion: number
  } | null
  allowDownloadReport: boolean
}
export interface UseXlsReportParams {
  readonly checkupVersion?: number
  readonly checkupId?: number
  checkupsService: CheckupsService
}

function useCheckupRuleStatus({
  checkupKey,
  checkupsService,
}: {
  readonly checkupKey: string | null
  readonly checkupId?: number
  checkupsService: CheckupsService
}): api.Checkup.Status | null {
  return useStoreMap({
    store: checkupsService.checkup.checkupStatus$,
    keys: [checkupKey],
    fn(checkupRuleStatus, [key]) {
      if (!key || !checkupRuleStatus) {
        return null
      }
      return checkupRuleStatus[key] || null
    },
  })
}

function useXlsReport({
  checkupVersion,
  checkupId,
  checkupsService,
}: UseXlsReportParams): string {
  return useStoreMap({
    store: checkupsService.checkup.checkupStatus$,
    keys: [checkupVersion, checkupId],
    fn(checkupRuleStatus, [checkupVersion, checkupId]) {
      if (!checkupId || !checkupVersion || !checkupRuleStatus) {
        return ''
      }
      return checkupRuleStatus[
        buildCheckupStatusKey({ checkupId, checkupVersion })
      ] === 'Finished'
        ? `/v1/checkup-calculation/checkup/${checkupId}/version/${checkupVersion}/download-report-xlsx`
        : ''
    },
  })
}

export function useCheckup({
  checkupsService,
}: {
  readonly checkupsService: CheckupsService
}): UseCheckupValue {
  const currentCheckup = useStore(checkupsService.checkup.currentCheckup$)
  const allCurrentRules = useStoreMap({
    store: checkupsService.checkupRule.rule$,
    keys: [currentCheckup],
    fn: (rules, [currCh]) => currCh?.rules.map(id => rules[id]) || null,
  })

  const checkup = React.useMemo(
    (): UseCheckupValue['checkup'] =>
      currentCheckup
        ? {
            checkupId: currentCheckup.id,
            checkupVersion: currentCheckup.version,
          }
        : null,
    [currentCheckup],
  )

  const checkupKey = checkup
    ? buildCheckupStatusKey({
        checkupId: checkup.checkupId,
        checkupVersion: checkup.checkupVersion,
      }) || null
    : null

  const xlsxReportUrl = useXlsReport({
    checkupId: checkup?.checkupId,
    checkupVersion: currentCheckup?.version,
    checkupsService,
  })

  const checkupStatus = useCheckupRuleStatus({
    checkupKey,
    checkupsService,
  })

  const pendingRequest = useStore(api.Checkup.start.defaultContext.pending$)

  const pendingStatus =
    checkupStatus === 'InProgress' || checkupStatus === 'Started'

  const pending = pendingRequest || pendingStatus

  const finished = checkupStatus === 'Finished'

  const emptyCondition = React.useMemo(
    () =>
      !allCurrentRules?.some(
        r =>
          r &&
          (r.simpleConditions.length > 0 || r.complexConditions.length > 0),
      ),
    [allCurrentRules],
  )

  const error = !!(checkupStatus && checkupStatus === 'Error')

  return React.useMemo(
    () => ({
      xlsxReportUrl,
      checkupStatus,
      pending,
      checkup,
      checkupKey,
      error,
      finished,
      emptyCondition,
      allowDownloadReport: !!currentCheckup?.allowDownloadReport,
    }),
    [
      xlsxReportUrl,
      checkupStatus,
      pending,
      checkup,
      checkupKey,
      error,
      finished,
      emptyCondition,
      currentCheckup?.allowDownloadReport,
    ],
  )
}
