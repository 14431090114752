import { combine, createEvent, createStore, guard, sample } from 'effector'

import { isNotEmpty } from '@gmini/utils'

import { Color } from '@gmini/common/lib/forge-viewer/model/themeModel'

import { currentCheckupStatus$ } from '../checkupStatus'

import { currentInclusionMap$ } from '../inclusionModel'

import { currentLoadedModels$ } from '../checkup.init'

import {
  colorViewerElements,
  reFetchColorModels,
  modelLoaded,
  themeModelsLoaded,
} from './model'
import { fetchBulk } from './requests'

type ColoredElements = Record<
  string,
  {
    GREEN?: string[]
    RED?: string[]
    YELLOW?: string[]
    GRAY?: string[]
    WHITE?: string[]
  }
>

/**
 * Окрашиваем модель цветами
 */

const coloredModels$ = createStore<ColoredElements>({}).on(
  themeModelsLoaded,
  (state, { urns, coloredModels }) => {
    let theme: ColoredElements = {}

    if (!coloredModels) {
      return state
    }

    urns.forEach(urn => {
      const modelItems = coloredModels && coloredModels[urn]
      if (!modelItems) {
        theme = {}
        return
      }

      theme = {
        ...theme,
        [urn]: {
          [Color.GREEN]: modelItems.successItems.filter(
            externalId => !modelItems.failedItems.includes(externalId),
          ),
          [Color.RED]: modelItems.failedItems.filter(isNotEmpty),
        },
      }
    })

    return Object.keys(theme).length ? { ...state, ...theme } : state
  },
)

coloredModels$.watch(colorViewerElements)

const fetchOnce = createEvent<{
  modelId: number
  urn: string
  baseClassifierId: number
  checkupId: number
  checkupVersion: number
}>()

const includedAndLoadedModels$ = combine(
  {
    currentLoadedModels: currentLoadedModels$,
    currentInclusionMap: currentInclusionMap$,
  },
  ({ currentInclusionMap, currentLoadedModels }) =>
    currentLoadedModels?.filter(model =>
      currentInclusionMap
        ? Object.keys(currentInclusionMap[model.baseClassifierId] || []).length
        : null,
    ),
)

sample({
  source: combine({
    includedAndLoadedModels: includedAndLoadedModels$,
    currentCheckupStatus: currentCheckupStatus$,
  }),
  clock: modelLoaded,
  fn: ({ includedAndLoadedModels, currentCheckupStatus }, addedModel) =>
    currentCheckupStatus === 'Finished'
      ? includedAndLoadedModels?.find(model => model.urn === addedModel.urn)
      : null,
}).watch(data => {
  if (data) {
    fetchOnce(data)
  }
})

sample({
  source: includedAndLoadedModels$,
  clock: reFetchColorModels,
  fn: includedAndLoadedModels =>
    includedAndLoadedModels ? includedAndLoadedModels : null,
})
  .filter({ fn: isNotEmpty })
  .watch(fetchBulk)

guard({
  clock: fetchOnce.map(data => [data]),
  filter: Boolean,
  target: fetchBulk,
})
