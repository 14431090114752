import { FetchService } from '@gmini/utils'

import * as api from './api'

export function checksConnect(checksFetch: FetchService) {
  api.CheckupRepo.Populated.fetch.use(({ projectUrn }) =>
    checksFetch
      .get(`/v1/checkup-repo/project/${projectUrn}`)
      .then(api.CheckupRepo.Populated.fetch.toPromise),
  )

  api.CheckupRepoFolder.create.use(({ parentFolderId, name, projectUrn }) =>
    checksFetch
      .post(`/v1/checkup-repo/project/${projectUrn}/folder`, {
        parentFolderId,
        name,
      })
      .then(api.CheckupRepoFolder.create.toPromise),
  )

  api.CheckupRepoFolder.Populated.fetchSilent.use(({ id }) =>
    checksFetch
      .get(`/v1/checkup-repo/folder/${id}`, { skip403: true })
      .then(api.CheckupRepoFolder.Populated.fetchSilent.toPromise),
  )

  api.CheckupRepoFolder.Populated.fetch.use(({ id }) =>
    checksFetch
      .get(`/v1/checkup-repo/folder/${id}`)
      .then(api.CheckupRepoFolder.Populated.fetch.toPromise),
  )

  api.CheckupRepoFolder.rename.use(({ id, name, parentFolderId }) =>
    checksFetch
      .patch(`/v1/checkup-repo/folder/${id}`, { name, parentFolderId })
      .then(api.CheckupRepoFolder.rename.toPromise),
  )

  api.CheckupRepoFolder.move.use(({ id, parentFolderId }) =>
    checksFetch
      .patch(`/v1/checkup-repo/folder/${id}`, { parentFolderId })
      .then(api.CheckupRepoFolder.move.toPromise),
  )

  api.CheckupRepoFolder.remove.use(({ id }) =>
    checksFetch
      .delete(`/v1/checkup-repo/folder/${id}`)
      .then(api.CheckupRepoFolder.remove.toPromise),
  )

  api.Checkup.create.use(({ parentFolderId, name, projectUrn }) =>
    checksFetch
      .post(`/v1/checkup-repo/project/${projectUrn}/checkup`, {
        parentFolderId,
        name,
      })
      .then(api.Checkup.create.toPromise),
  )

  api.Checkup.copy.use(({ id, name, version }) =>
    checksFetch
      .post(`/v1/checkup-repo/checkup/${id}/version/${version}/copy`, { name })
      .then(api.Checkup.create.toPromise),
  )

  api.Checkup.rename.use(({ id, name, parentFolderId, version }) =>
    checksFetch
      .patch(`/v1/checkup-repo/checkup/${id}/version/${version}`, {
        name,
        parentFolderId,
      })
      .then(api.Checkup.rename.toPromise),
  )

  api.Checkup.move.use(({ id, parentFolderId, version }) =>
    checksFetch
      .patch(`/v1/checkup-repo/checkup/${id}/version/${version}`, {
        parentFolderId,
      })
      .then(api.Checkup.move.toPromise),
  )

  api.Checkup.remove.use(({ id }) =>
    checksFetch
      .delete(`/v1/checkup-repo/checkup/${id}`)
      .then(api.Checkup.remove.toPromise),
  )

  api.Checkup.getMostRecent.use(({ id }) =>
    checksFetch
      .get(`/v1/checkup-repo/checkup/${id}/most-recent`)
      .then(res => res)
      .then(api.Checkup.getMostRecent.toPromise),
  )

  api.Checkup.getMostRecentSilent.use(({ id }) =>
    checksFetch
      .get(`/v1/checkup-repo/checkup/${id}/most-recent`)
      .then(res => res)
      .then(api.Checkup.getMostRecentSilent.toPromise),
  )

  api.CheckupRule.updateConditions.use(
    ({
      checkupId,
      simpleConditions,
      complexConditions,
      notCalculatedElementProperties,
      checkupVersion,
      ruleId,
    }) =>
      checksFetch
        .patch(
          `/v1/checkup-repo/checkup/${checkupId}/version/${checkupVersion}/rule/${ruleId}`,
          {
            simpleConditions,
            complexConditions,
            notCalculatedElementProperties,
          },
        )
        .then(api.CheckupRule.updateConditions.toPromise),
  )

  api.CheckupRule.validateFormula.use(({ formula }) =>
    checksFetch
      .post(`/v1/checkup-repo/validate-checkup-rule-formula`, { formula })
      .then(api.CheckupRule.validateFormula.toPromise),
  )

  api.CheckupRule.create.use(({ checkupId, checkupVersion, groupId }) =>
    checksFetch
      .post(
        `/v1/checkup-repo/checkup/${checkupId}/version/${checkupVersion}/rule`,
        {
          groupId,
          simpleConditions: [],
          complexConditions: [],
          notCalculatedElementProperties: [],
        },
      )
      .then(api.CheckupRule.create.toPromise),
  )

  api.CheckupRule.remove.use(({ checkupId, checkupVersion, ruleId }) =>
    checksFetch
      .delete(
        `/v1/checkup-repo/checkup/${checkupId}/version/${checkupVersion}/rule/${ruleId}`,
      )
      .then(api.CheckupRule.remove.toPromise),
  )

  api.Checkup.fetch.use(({ checkupId, checkupVersion }) =>
    checksFetch
      .get(`/v1/checkup-repo/checkup/${checkupId}/version/${checkupVersion}`)
      .then(api.Checkup.fetch.toPromise),
  )

  api.Checkup.fetchStatus.use(({ checkupId, checkupVersion }) =>
    checksFetch
      .get(
        `/v1/checkup-calculation/checkup/${checkupId}/version/${checkupVersion}/status`,
      )
      .then(api.Checkup.fetchStatus.toPromise),
  )

  api.Checkup.start.use(({ checkupId, checkupVersion }) =>
    checksFetch
      .post(`/v1/checkup-calculation/start`, {
        checkupId,
        checkupVersion,
      })
      .then(api.Checkup.start.toPromise),
  )

  api.Checkup.modelElementCheckupStatus.use(
    ({ checkupId, checkupVersion, modelId }) =>
      checksFetch
        .get(
          `/v1/checkup-repo/checkup/${checkupId}/version/${checkupVersion}/model/${modelId}/model-element-checkup-status`,
        )
        .then(api.Checkup.modelElementCheckupStatus.toPromise),
  )

  api.FailedNode.get.use(({ checkupId, items, checkupVersion, ruleId }) =>
    checksFetch
      .post('/v1/checkup-results/calculate-items-failed-status', {
        checkupId,
        items,
        checkupVersion,
        ruleId,
      })
      .then(api.FailedNode.get.toPromise),
  )

  api.Checkup.fetchVersionDates.use(({ checkupId }) =>
    checksFetch
      .get(`/v1/checkup-repo/checkup/${checkupId}/version/date`)
      .then(api.Checkup.fetchVersionDates.toPromise),
  )

  api.Checkup.fetchVersionByDate.use(({ checkupId, versionDate }) =>
    checksFetch
      .get(`/v1/checkup-repo/checkup/${checkupId}/version/date/${versionDate}`)

      .then(api.Checkup.fetchVersionByDate.toPromise),
  )
  api.Checkup.renameVersion.use(({ checkupId, checkupVersion, versionName }) =>
    checksFetch
      .post(
        `/v1/checkup-repo/checkup/${checkupId}/version/${checkupVersion}/name`,
        { versionName },
      )
      .then(api.Checkup.renameVersion.toPromise),
  )
  api.Checkup.removeVersionName.use(({ checkupId, checkupVersion }) =>
    checksFetch
      .delete(
        `/v1/checkup-repo/checkup/${checkupId}/version/${checkupVersion}/name`,
      )
      .then(api.Checkup.removeVersionName.toPromise),
  )
  api.Checkup.fetchNamedVersions.use(({ checkupId }) =>
    checksFetch
      .get(`/v1/checkup-repo/checkup/${checkupId}/version/name`)
      .then(api.Checkup.fetchNamedVersions.toPromise),
  )
}
