import { createEvent, createStore, forward } from 'effector'

import { equals } from 'ramda'

import { buildNodeVariantsPendingKey } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/pendingModel'

import * as api from '../../../../api'

import { checkupsService } from '../../../../services/checkupsService'
import { colorViewerElements } from '../viewerTheme'

// import { reasonToText } from './reasonToText'

// Уже запрошенные ранее элементы
const resetOldRequestItems = createEvent()
export const oldRequestItems$ = createStore<
  Record<string, api.FailedNodeReqItem>
>({})
  .on(
    api.FailedNode.get.done,
    (state, { params: { ruleId, items: newItems } }) =>
      newItems.reduce(
        (acc, item) => {
          acc[
            buildFailedNodeReqKey({
              id: item.itemId,
              type: item.itemType,
              ruleId,
            })
          ] = item
          return { ...acc }
        },
        { ...state },
      ),
  )
  .reset(resetOldRequestItems)

// При сбросе current сбрасываем историю запрошенных элементов
forward({
  from: checkupsService.failedNode.resetCurrent,
  to: resetOldRequestItems,
})

oldRequestItems$
  .map((state, oldState?: Record<string, api.FailedNodeReqItem>) =>
    oldState && equals(state, oldState) ? oldState : state,
  )
  .watch(old => {
    if (!Object.keys(old).length) {
      colorViewerElements({})
    }
  })

export const userClassifierNodeVariantsPendingMap$ = createStore<
  Record<string, true>
>({})
  .on(api.FailedNode.get.trigger, (state, { items }) => {
    const nextState = items.reduce(
      (acc, { itemId, itemType }) => {
        acc[buildNodeVariantsPendingKey({ type: itemType, id: itemId })] = true
        return acc
      },
      { ...state },
    )
    return nextState
  })
  .on(api.FailedNode.get.finally, (state, { params: { items } }) => {
    const nextState = items.reduce(
      (acc, { itemId, itemType }) => {
        delete acc[buildNodeVariantsPendingKey({ type: itemType, id: itemId })]
        return acc
      },
      { ...state },
    )
    return nextState
  })

export function buildFailedNodeReqKey({
  type,
  id,
  ruleId,
}: {
  id: number
  type: api.FailedNodeType | 'UserClassifier'
  ruleId: number
}): string {
  return id + type + ruleId
}

export function reasonSort(
  reasons: readonly api.FailedNodeReason[],
): api.FailedNodeReason[] {
  return [
    ...reasons.filter(reason => reason.conditionType === 'SimpleCondition'),
    ...reasons.filter(reason => reason.conditionType === 'ComplexCondition'),
  ]
}

export function reasonSortByArr({
  reasons,
  simpleArr,
  complexArr,
}: {
  reasons: readonly api.FailedNodeReason[]
  simpleArr: number[]
  complexArr: string[]
}): api.FailedNodeReason[] {
  return [
    ...reasons
      .filter(api.SimpleReason.is)
      .sort(
        (a, b) => simpleArr.indexOf(a.fieldId) - simpleArr.indexOf(b.fieldId),
      ),
    ...reasons
      .filter(api.ComplexReason.is)
      .sort(
        (a, b) =>
          complexArr.indexOf(a.conditionName) -
          complexArr.indexOf(b.conditionName),
      ),
  ]
}
