import { createStore, sample, merge, createEvent } from 'effector'

import { isNotEmpty } from '@gmini/utils'

import {
  Color,
  createViewerColoredModel,
} from '@gmini/common/lib/forge-viewer/model/themeModel'

import * as smApi from '@gmini/sm-api-sdk'

import * as api from '../../../../api'

import { currentCheckupStatus$ } from '../checkupStatus'

import { currentLoadedModels$ } from '../checkup.init'

import { classifierService } from '../../../../services/classifierService'

import { fetchBulk } from './requests'

export const defaultViewerThemeColor = Color.GRAY

export const {
  modelLoaded,
  modelRemoved,
  colorViewerElements,
} = createViewerColoredModel()

export const reFetchColorModels = createEvent<void>()

let theme = false

const onChangeTheme = (value: boolean) => {
  if (!value) {
    colorViewerElements({})
  }

  if (!theme && value) {
    reFetchColorModels()
  }
  theme = value
}

currentCheckupStatus$.watch(status => {
  onChangeTheme(status === 'Finished')
})

sample({
  source: currentLoadedModels$,
  clock: reFetchColorModels,
  fn: currentLoadedModels =>
    currentLoadedModels?.map(model => model.urn).filter(isNotEmpty),
}).watch(urns => {
  if (urns) {
    // setDefaultColor(urns)
  }
})

const coloredModelsReset = merge([
  reFetchColorModels,
  modelRemoved,
  classifierService.reset,
])

const coloredModelsStore$ = createStore<Record<
  string,
  Omit<smApi.ModelElementStatus, 'urn'>
> | null>(null)
  .on(
    api.Checkup.modelElementCheckupStatus.defaultContext.doneData,
    (state, { urn, successItems, failedItems }) => ({
      ...state,
      [urn]: { successItems, failedItems },
    }),
  )
  .reset(coloredModelsReset)

/**
 * ExternalIdMap модели загрузилась во вьювере или модели загружены и был bulk запрос
 */
const modelsLoadedEvent = merge([
  modelLoaded.map(({ urn }) => urn),
  fetchBulk.doneData,
])

export const themeModelsLoaded = sample({
  source: coloredModelsStore$,
  clock: modelsLoadedEvent,
  fn: (coloredModels, urn) => ({
    coloredModels,
    urns: Array.isArray(urn) ? urn : [urn],
  }),
})
