import { createEvent, createStore, Event, Store } from 'effector'

import * as api from '../../api'

export type CheckupStoreService = {
  resetCurrent: Event<void>
  currentCheckup$: Store<CheckupEntity | null>
  checkupStatus$: Store<CheckupResultStoreStatus | null>
}

export type CurrentCheckup$ = Store<CheckupEntity | null>

export type CheckupEntity = Omit<api.Checkup, 'rules'> & {
  rules: number[]
}

export type CheckupResultStoreStatus = Readonly<
  Record<string, undefined | api.Checkup.Status>
>

export function createCheckupStoreService({
  updatedEvent,
}: {
  updatedEvent: Event<api.NotificationEvent.Update.Payload>
}): CheckupStoreService {
  const resetCurrent = createEvent()

  const currentCheckup$ = createStore<CheckupEntity | null>(null).reset(
    resetCurrent,
  )

  currentCheckup$
    .on(api.Checkup.fetch.doneData, (_, data) => {
      const rules = data.rules.map(r => r.id)
      return { ...data, rules }
    })
    .on(api.Checkup.getMostRecent.doneData, (_, data) => {
      const rules = data.rules.map(r => r.id)
      return { ...data, rules }
    })
    .on(updatedEvent.filter({ fn: api.Checkup.is }), (prev, next) => {
      if (prev && prev.id === next.id) {
        const rules = next.rules.map(r => r.id)
        return { ...next, rules }
      }

      return prev
    })

  const checkupStatus$ = createStore<CheckupResultStoreStatus | null>(null)
    .on(
      api.Checkup.start.defaultContext.done,
      (_, { params: { checkupId, checkupVersion } }) => ({
        [buildCheckupStatusKey({
          checkupId,
          checkupVersion,
        })]: 'Started',
      }),
    )
    .on(
      api.Checkup.fetchStatus.defaultContext.done,
      (_, { result: { id, version, status } }) => ({
        [buildCheckupStatusKey({
          checkupId: id,
          checkupVersion: version,
        })]: status,
      }),
    )

  return {
    currentCheckup$,
    resetCurrent,
    checkupStatus$,
  }
}

export function buildCheckupStatusKey({
  checkupId,
  checkupVersion,
}: {
  checkupId: number
  checkupVersion: number
}) {
  return `${checkupId}:${checkupVersion}`
}
