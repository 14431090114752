import { createTreeModel } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/treeModel'
import {
  updateChecked,
  resetChecked,
} from '@gmini/common/lib/classifier-editor'
import { ViewerCheckedMap } from '@gmini/common/lib/classifier-editor/FromTreeToViewer/types'
import { isApiFlatNode } from '@gmini/common/lib/classifier-editor/ClassifierTree/createTree'
import { createExpandModel } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/expandModel'
import { dynamicGroupMode$ } from '@gmini/common/lib/classifier-editor/ClassifierTree/dynamicGroupMode'
import { subscribeSelectNewGroup } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/groupModel'
import * as smApi from '@gmini/sm-api-sdk'

import { combine, sample } from 'effector'

import { resetShowMode } from '@gmini/common/lib/forge-viewer/model/selectModel'

import { currentUserClassifier$ } from '../../CurrentUserClassifier'
import { classifierService } from '../../../services/classifierService'
import { editorCheckedModel } from '../core/checkedModel'
import { createFailedNodesFilterModel } from '../core/failedNodesFilterModel'

export const { flatTree$, ...restTreeModel } = createTreeModel({
  currentUserClassifier$,
  nodes$: classifierService.nodes$,
  onCreatedNode: smApi.UserClassifierGroup.create.done,
  dynamicMode$: dynamicGroupMode$,
  treeOrderMapTriggers: [
    smApi.UserClassifierPriorityOrder.GetAll.fetch.doneData,
    smApi.UserClassifierPriorityOrder.Recalculate.submit.doneData,
  ],
})

export const {
  filteredFlatTree$,
  filterIsOn$: failedNodesFilterIsOn$,
  setFilter: setFailedNodesFilter,
} = createFailedNodesFilterModel({
  flatTree$,
  nodes$: classifierService.nodes$,
})

export const treeModel = {
  ...restTreeModel,
  flatTree$: filteredFlatTree$,
}

subscribeSelectNewGroup({
  flatTree$: treeModel.flatTree$,
  onCreatedNode: smApi.UserClassifierGroup.create.doneData,
})

export const expandModel = createExpandModel()

const checkedWithTree$ = combine({
  tree: filteredFlatTree$,
  checked: editorCheckedModel.checked$,
})

// Для работы с чекнутыми элементами во вьювере
// TODO После рефакторинга логики fromEditorToViewer выпилить
sample({
  clock: editorCheckedModel.checked$.updates,
  source: checkedWithTree$,
  fn: ({ checked, tree }, currentCheckedMap) => ({
    checked,
    tree,
    currentCheckedMap,
  }),
}).watch(({ checked, currentCheckedMap, tree }) => {
  if (Object.keys(currentCheckedMap).length > 0) {
    const keys = Object.keys(checked)
    const flatNodes = tree.reduce((acc, node) => {
      if (
        isApiFlatNode(node) &&
        keys.some(k => node.path.join(':') === k && checked[k])
      ) {
        acc[node.ref.type + node.ref.id] = node.ref
      }

      return acc
    }, {} as ViewerCheckedMap)
    updateChecked({
      path: 'Own',
      checkedMap: flatNodes,
    })
  } else {
    resetChecked('Own')
    resetShowMode()
  }
})
