import { createEvent, createStore, guard } from 'effector'
import * as smApi from '@gmini/sm-api-sdk'

import * as api from '../../../api'

import { currentRule$ } from '../../CurrentRule'

export type SelectPropertiesParams = {
  properties: smApi.UserClassifierGroup.Property['id'][]
  checkupId: number
  checkupVersion: number
  ruleId: number
}

const setProperties = createEvent<smApi.UserClassifierGroup.Property['id'][]>()
export const selectProperties = createEvent<SelectPropertiesParams>()
export const selectedProperties$ = createStore<
  smApi.UserClassifierGroup.Property['id'][]
>([])
  .on(selectProperties, (_, { properties }) => properties)
  .on(setProperties, (_, properties) => properties)

export const saveSimpleConditionsApi = api.CheckupRule.updateConditions.createContext()

export const selectPropertiesPending$ = saveSimpleConditionsApi.pending$

guard({
  source: currentRule$.map(rule =>
    rule?.simpleConditions.map(condition => condition.elementPropertyId),
  ),
  filter: Boolean,
  target: setProperties,
})
