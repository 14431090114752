import * as t from 'io-ts'

import { ApiCallService } from '@gmini/api-call-service'

import { Id } from '@gmini/sm-api-sdk'

import { ApiDomain } from '@gmini/sm-api-sdk/lib/domain'

import { createPredicate } from '../createPredicate'

import { Checkup } from './Checkup'

export interface CheckupRepoFolder {
  readonly type: 'CheckupRepositoryFolder'
  readonly id: Id
  readonly parentFolderId: null | Id
  readonly name: string
  readonly total: number
  readonly projectUrn: string
}

export namespace CheckupRepoFolder {
  const domain = ApiDomain.domain('CheckupRepoFolder')

  export const is = createPredicate<CheckupRepoFolder>(
    'CheckupRepositoryFolder',
  )

  export const io: t.Type<CheckupRepoFolder> = t.type(
    {
      type: t.literal('CheckupRepositoryFolder'),
      id: Id,
      parentFolderId: t.union([t.null, Id]),
      name: t.string,
      total: t.number,
      projectUrn: t.string,
    },
    'CheckupRepositoryFolder',
  )

  export interface Populated {
    readonly type: 'CheckupRepositoryFolderPopulated'
    readonly id: Id
    readonly parentFolderId: null | Id
    readonly name: string
    readonly total: number
    readonly offset: number
    readonly children: ReadonlyArray<CheckupRepoFolder | Checkup>
    readonly projectUrn: string
  }

  export namespace Populated {
    export const is = createPredicate<Populated>(
      'CheckupRepositoryFolderPopulated',
    )

    export const io: t.Type<Populated> = t.recursion(
      'CheckupRepositoryFolderPopulated',
      () =>
        t.type({
          type: t.literal('CheckupRepositoryFolderPopulated'),
          id: Id,
          parentFolderId: t.union([t.null, Id]),
          name: t.string,
          total: t.number,
          offset: t.number,
          children: t.readonlyArray(
            t.union([CheckupRepoFolder.io, Checkup.io]),
          ),
          projectUrn: t.string,
        }),
    )

    export const fetch = ApiCallService.create<{ readonly id: Id }, Populated>({
      name: 'fetch',
      parentDomain: domain,
      result: CheckupRepoFolder.Populated.io,
      getKey: ['id'],
    })

    export const fetchSilent = ApiCallService.create<
      { readonly id: Id },
      Populated
    >({
      name: 'fetchSilent',
      parentDomain: domain,
      result: CheckupRepoFolder.Populated.io,
      getKey: ['id'],
    })
  }

  export const create = ApiCallService.create<
    {
      readonly parentFolderId?: null | Id
      readonly name: string
      readonly projectUrn: string
    },
    CheckupRepoFolder
  >({
    name: 'create',
    parentDomain: domain,
    result: CheckupRepoFolder.io,
    getKey: ['name', 'parentFolderId'],
  })

  export const rename = ApiCallService.create<
    {
      readonly id: Id
      readonly parentFolderId: null | Id
      readonly name: string
    },
    CheckupRepoFolder
  >({
    name: 'rename',
    parentDomain: domain,
    result: CheckupRepoFolder.io,
    getKey: ['id', 'parentFolderId', 'name'],
  })

  export const move = ApiCallService.create<
    {
      readonly id: Id
      readonly parentFolderId: null | Id
    },
    CheckupRepoFolder
  >({
    name: 'move',
    parentDomain: domain,
    result: CheckupRepoFolder.io,
    getKey: ['id', 'parentFolderId'],
  })

  export const remove = ApiCallService.create<
    { readonly id: Id },
    CheckupRepoFolder
  >({
    name: 'remove',
    parentDomain: domain,
    result: CheckupRepoFolder.io,
    getKey: ['id'],
  })
}
