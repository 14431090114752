import { FetchService } from '@gmini/utils'

import { setAuthHeader, removeAuthHeader } from '@gmini/common/lib/auth/auth'

import * as smApi from '@gmini/sm-api-sdk'
import * as gStationApi from '@gmini/sm-api-sdk/lib/gStationConnect/gStationConnect'

import { refreshTokenFunc } from '@gmini/common/lib/keycloakAuth/auth/authData'

import { tokenData$ } from '@gmini/common/lib/keycloakAuth'

import * as api from '../api'

import { gStationDocumentManagementUrl } from '../config'

export const authApi = new FetchService({ baseUrl: '/api/auth/v1' })

export const checksFetch = new FetchService({
  baseUrl: '/api/checkups',
  refreshTokenFunc,
})

export const checksReportsFetch = new FetchService({
  baseUrl: '/api/reports',
  refreshTokenFunc,
})

export const gStation = new FetchService({
  baseUrl: gStationDocumentManagementUrl,
})

tokenData$.watch(tokenData => {
  if (tokenData?.accessToken) {
    setAuthHeader(
      [checksFetch, checksReportsFetch, authApi, gStation],
      tokenData.accessToken,
    )
  } else {
    removeAuthHeader([checksFetch, checksReportsFetch, authApi, gStation])
  }
})

smApi.Auth.User.fetch.use(() =>
  authApi.get(`/auth/current-user`).then(smApi.Auth.User.fetch.toPromise),
)

export function checksServiceConnect() {
  api.checksConnect(checksFetch)
}

gStationApi.gStationConnect(gStation)
