import { combine, guard, sample } from 'effector'

import { equals } from 'ramda'

import { checkupsService } from '../../../services/checkupsService'

import { saveSimpleConditionsApi, selectProperties } from './simpleConditions'
import {
  saveAdditionalConditionsApi,
  selectAdditionalProperties,
} from './additionalConditions'
import {
  unsavedConditionsByRule$,
  saveComplexConditions,
  saveComplexConditionsApi,
  deleteCondition,
  addCondition,
} from './complexConditions'

sample({
  clock: selectAdditionalProperties,
  source: combine({
    checkup: checkupsService.checkup.currentCheckup$,
    complexConditionsByRule: unsavedConditionsByRule$,
    rulesMap: checkupsService.checkupRule.rule$,
  }),
  fn: (
    { checkup, complexConditionsByRule, rulesMap },
    { properties, ruleId },
  ) =>
    checkup
      ? {
          ruleId,
          checkupId: checkup.id,
          checkupVersion: checkup.version,
          simpleConditions: rulesMap[ruleId]?.simpleConditions || [],
          complexConditions:
            complexConditionsByRule[ruleId]?.map(({ name, formula }) => ({
              name,
              formula,
            })) || [],
          notCalculatedElementProperties: properties,
          rulesMap,
        }
      : null,
}).watch(params => {
  const existChanged = !equals(
    params?.notCalculatedElementProperties,
    params?.rulesMap?.[params?.ruleId]?.notCalculatedElementProperties,
  )

  if (!params || !existChanged) {
    return
  }

  const { rulesMap, ...other } = params

  saveAdditionalConditionsApi.submit({
    ...other,
  })
})

guard({
  source: sample({
    clock: selectProperties,
    source: combine({
      checkup: checkupsService.checkup.currentCheckup$,
      complexConditionsByRule: unsavedConditionsByRule$,
      rulesMap: checkupsService.checkupRule.rule$,
    }),
    fn: (
      { checkup, complexConditionsByRule, rulesMap },
      { properties, ruleId },
    ) =>
      checkup
        ? {
            ruleId,
            checkupId: checkup.id,
            checkupVersion: checkup.version,
            simpleConditions: properties.map(property => ({
              elementPropertyId: property,
            })),
            complexConditions:
              complexConditionsByRule[ruleId]?.map(({ name, formula }) => ({
                name,
                formula,
              })) || [],
            notCalculatedElementProperties:
              rulesMap[ruleId]?.notCalculatedElementProperties || [],
          }
        : null,
  }),
  filter: Boolean,
  target: saveSimpleConditionsApi.submit,
})

sample({
  clock: [saveComplexConditions, deleteCondition, addCondition],
  source: combine({
    checkup: checkupsService.checkup.currentCheckup$,
    complexConditionsByRule: unsavedConditionsByRule$,
    rulesMap: checkupsService.checkupRule.rule$,
  }),
  fn: ({ checkup, complexConditionsByRule, rulesMap }, { ruleId }) =>
    checkup
      ? {
          ruleId,
          checkupId: checkup.id,
          checkupVersion: checkup.version,
          simpleConditions: rulesMap[ruleId]?.simpleConditions || [],
          complexConditions:
            complexConditionsByRule[ruleId]?.map(({ name, formula }) => ({
              name,
              formula,
            })) || [],
          notCalculatedElementProperties:
            rulesMap[ruleId]?.notCalculatedElementProperties || [],
          rulesMap,
        }
      : null,
}).watch(params => {
  const existChanged = !equals(
    params?.complexConditions.map(({ name, formula }) => ({ name, formula })),
    params?.rulesMap?.[
      params?.ruleId
    ]?.complexConditions.map(({ name, formula }) => ({ name, formula })),
  )

  if (!params || !existChanged) {
    return
  }

  const { rulesMap, ...other } = params

  saveComplexConditionsApi.submit({
    ...other,
  })
})
