import { Version } from '@gmini/common/lib/classifier-service/Node'

import * as smApi from '@gmini/sm-api-sdk'

import { Id } from './Id'
// eslint-disable-next-line import/no-cycle
import { Node } from './Node'
// eslint-disable-next-line import/no-cycle
import { CheckupRepoNode } from './CheckupRepoNode'
// eslint-disable-next-line import/no-cycle
import { CheckupRepoFolderNode } from './CheckupRepoFolderNode'
// eslint-disable-next-line import/no-cycle
import { CheckupRuleNode } from './CheckupRuleNode'

export interface CheckupNode {
  readonly type: 'CheckupNode'
  readonly id: Id
  readonly version: Version
  readonly parentFolderId: null | Id
  readonly key: string
  readonly parentKey: null | string
  readonly name: string
  readonly rules: readonly CheckupRuleNode[]
  readonly classifierId: number
  readonly readOnly: boolean
  readonly owner: string | null
  readonly projectUrn: smApi.Urn
}

export namespace CheckupNode {
  export type Type = CheckupNode['type']
  export const TYPE: Type = 'CheckupNode'

  export type Data = Omit<
    CheckupNode,
    'type' | 'rules' | 'key' | 'parentKey'
  > & {
    readonly rules: ReadonlyArray<CheckupRuleNode.Data>
  }

  export const create = ({ rules, ...source }: Data): CheckupNode => ({
    ...source,
    rules: rules.map(CheckupRuleNode.create),
    type: TYPE,
    key: getKey(source),
    parentKey: getParentKey(source),
  })

  export type Ref = Pick<CheckupNode, 'type' | 'id'>

  export const is = (node: Node): node is CheckupNode =>
    node.type === 'CheckupNode'

  export const getKey = ({ id }: Pick<CheckupNode, 'id'>): string =>
    `${TYPE}:${id}`

  export const getParentKey = ({
    parentFolderId,
    projectUrn,
  }: Pick<CheckupNode, 'parentFolderId' | 'projectUrn'>): string =>
    parentFolderId == null
      ? CheckupRepoNode.getKey({ id: projectUrn })
      : CheckupRepoFolderNode.getKey({ id: parentFolderId })

  export const getByKey = (
    nodes: Node.Map,
    key: string,
  ): null | CheckupNode => {
    const node = nodes[key]
    return node && is(node) ? node : null
  }
}
