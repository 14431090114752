import * as React from 'react'
import { merge, Event, sample } from 'effector'
import { useSnackbar } from 'notistack'
import { prop } from 'ramda'
import * as smApi from '@gmini/sm-api-sdk'

import { ApiCallService } from '@gmini/api-call-service'

import {
  migrateFailMessage,
  migrateSystemError,
} from '@gmini/common/lib/classifier-editor'

import { bimFileErrorMessageMap } from '@gmini/common/lib/classifier-editor/SelectDependencies/importErrorMessageMap'
import { ImportErrorNotify } from '@gmini/common'

import {
  classifierService,
  selectDependenciesClassifierService,
} from '../../services/classifierService'
import { checkupsService } from '../../services/checkupsService'
import { messageMap, notShownMessages } from '../../services/messageMap'
import { notificationService } from '../../services/notificationService'
import { userClassifierRepoService } from '../../services/userClassifierRepoServiceConnect'
import { seoEventManager } from '../../config'

const bimFileImportErrorEvent = notificationService.message
  .filter({ fn: smApi.NotificationEvent.Update.is })
  .map(prop('payload'))
  .filter({
    fn: payload =>
      smApi.BimFile.isBimFile(payload) ||
      smApi.BimFile.isModelRepositoryBimFile(payload),
  })
  .filter({
    fn: ({ status, errorCode }: smApi.BimFile) =>
      status === 'ImportError' && errorCode !== 'StructureError',
  }) as Event<smApi.BimFile>

const bimFileImportErrorMessage = bimFileErrorMessageMap(
  bimFileImportErrorEvent,
)

const seoEventErrorHandler = (errorMessage: string, checkupId?: number) => {
  seoEventManager.push({
    event: 'Gtech_Checkup_Error',
    payload: {
      checkupId,
      errorType: 'red',
      errorText: errorMessage,
    },
  })
}

export const Notifications = React.memo<Record<string, unknown>>(() => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  React.useEffect(() => {
    const openSnackbar = (message: string) => {
      enqueueSnackbar(message, {
        variant: 'error',
      })
    }

    const openImportErrorSnackbar = (
      data: {
        text: string
        name: string
        version: number
      } | null,
    ) => {
      if (!data) {
        return
      }
      enqueueSnackbar(data, {
        variant: 'error',
        // eslint-disable-next-line react/display-name
        content: key => (
          <ImportErrorNotify
            key={key}
            text={data.text}
            name={data.name}
            version={data.version}
          />
        ),
      })
    }

    sample({
      source: checkupsService.checkup.currentCheckup$,
      clock: [message, iOTSError.map(prop('message'))],
      fn: (checkup, error) => ({ checkup, error }),
    }).watch(({ checkup, error }) => {
      if (error) {
        seoEventErrorHandler(error, checkup?.id)
      }
    })

    const failSubscription = message.watch(error => {
      openSnackbar(error)
    })
    const iOTSSubscription = iOTSError.watch(({ errors, message }) => {
      console.error(errors)
      openSnackbar(message)
    })
    const bimFileImportErrorSubscription = bimFileImportErrorMessage.watch(
      openImportErrorSnackbar,
    )

    const errorSubscription = error.watch(() => {
      seoEventErrorHandler(unexpectedText)
      openSnackbar(unexpectedText)
    })

    return () => {
      failSubscription.unsubscribe()
      iOTSSubscription.unsubscribe()
      errorSubscription.unsubscribe()
      bimFileImportErrorSubscription.unsubscribe()
    }
  }, [closeSnackbar, enqueueSnackbar])

  return null
})

Notifications.displayName = 'Notifications'

const formatErrorMessage = (message: string, code: string | null) =>
  `${message}`

const unexpected = (errorCode: string | null) =>
  `${unexpectedText}. [${errorCode}]`

const unexpectedText =
  'Непредвиденная ошибка. Пожалуйста, обратитесь к разработчикам'

const fail = merge([
  classifierService.anyRequestFail,
  selectDependenciesClassifierService.anyRequestFail,
  migrateFailMessage,
  migrateSystemError,
]).map(({ errorCode, mappedMessage }) => mappedMessage || unexpected(errorCode))

const iOTSError = merge([
  userClassifierRepoService.IOTSError,
  selectDependenciesClassifierService.anyRequestIOTSError,
  classifierService.anyRequestIOTSError,
  checkupsService.IOTSError,
]).map(({ status: errorCode, errors }) => ({
  errors,
  message: unexpected(errorCode),
}))

const checkupsServiceMessage = checkupsService.fail
  .map(prop('data'))
  .map(prop('errorCode'))
  //TODO FSK-594 не отображаем юзеру ошибку рефреша токена
  .filter({ fn: code => !notShownMessages.includes(code) })
  .map(code =>
    formatErrorMessage(
      messageMap[code as keyof typeof messageMap] || unexpectedText,
      code,
    ),
  )

const message = merge([fail, checkupsServiceMessage])

const error = ApiCallService.fail.filterMap(({ params, name, error }) => {
  if (error.status === 'error') {
    return { params, name, error }
  }
})
