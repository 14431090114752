import React from 'react'
import { useStore, useStoreMap } from 'effector-react'

import { isNumber } from '@gmini/utils'

import * as smApi from '@gmini/sm-api-sdk'

import { generateCheckupRuleKey } from '../../../checkups-service'

import { checkupsService } from '../../../services/checkupsService'
import { useCurrentRule } from '../../CurrentRule/useCurrentRule'
import { currentUserClassifierProps$ } from '../../CurrentUserClassifier'

export function useRulePropertyList() {
  const currentRule = useCurrentRule()
  const currentClassifier = useStore(currentUserClassifierProps$)
  const pending = useStore(
    smApi.UserClassifierGroup.Property.List.fetchElementProperties
      .defaultContext.pending$,
  )

  React.useEffect(() => () => smApi.elementPropertiesCache.reset(), [])
  const propertyList = useStoreMap({
    store: checkupsService.property.byKey$,
    keys: [currentClassifier?.id, currentRule?.groupId],
    fn: (byRule, [classifierId, groupId]) =>
      isNumber(classifierId) && isNumber(groupId)
        ? byRule?.[
            generateCheckupRuleKey({
              classifierId,
              groupId,
            })
          ] || []
        : [],
  })

  return {
    propertyList,
    pending,
  }
}
