import { Id } from '@gmini/sm-api-sdk'
import * as t from 'io-ts'

// eslint-disable-next-line import/no-cycle
import { ComplexReason } from './ComplexReason'

export type SimpleReasonResult = 'NO_PROPERTY' | 'EMPTY_PROPERTY'
export type ConditionType = 'SimpleCondition' | 'ComplexCondition'

export interface SimpleReason {
  conditionType: 'SimpleCondition'
  fieldId: Id
  result: SimpleReasonResult
}

export namespace SimpleReason {
  export const io: t.Type<SimpleReason> = t.type(
    {
      conditionType: t.literal('SimpleCondition'),
      fieldId: Id,
      result: t.union([t.literal('NO_PROPERTY'), t.literal('EMPTY_PROPERTY')]),
    },
    'SimpleReason',
  )

  export const is = (r: SimpleReason | ComplexReason): r is SimpleReason =>
    r.conditionType === 'SimpleCondition'
}
