import { combine, createDomain, createEvent, createStore } from 'effector'

import persist from 'effector-localstorage'

import { Item } from '@gmini/common/lib/Explorer'

import * as smApi from '@gmini/sm-api-sdk'

import { isNotEmpty } from '@gmini/utils'

import { getChildren, node as n } from '../../checkups-service'

import { checkupsService } from '../../services/checkupsService'
import { seoEventManager } from '../../config'

const domain = createDomain()

export const reset = domain.event('reset')

type ChangeProjectInfoWithAuthorAndStage = smApi.Project & {
  envName: string
  userId?: string
}

export const setSelectedProject = createEvent<ChangeProjectInfoWithAuthorAndStage>()

export const selectedProject$ = createStore<ChangeProjectInfoWithAuthorAndStage | null>(
  null,
).on(setSelectedProject, (_state, result) => result)

persist({ store: selectedProject$, key: 'selectedProject' })

selectedProject$.updates.watch(
  (state: ChangeProjectInfoWithAuthorAndStage | null) => {
    seoEventManager.push({
      event: 'Gtech_SelectProject',
      payload: {
        projectUrn: state?.urn,
        userId: state?.userId,
        prod: state?.envName,
      },
    })
  },
)

const repo$ = combine(
  {
    selectedProject: selectedProject$,
    nodes: checkupsService.nodes$,
  },
  ({ nodes, selectedProject }) => {
    if (!selectedProject) {
      return null
    }

    const key = n.CheckupRepoNode.getKey({ id: selectedProject?.urn })

    return n.CheckupRepoNode.getByKey(nodes, key)
  },
)

const root$ = combine(
  {
    nodes: checkupsService.nodes$,
    relations: checkupsService.relations$,
    repo: repo$,
  },
  ({ nodes, relations, repo }) =>
    repo ? getChildren(nodes, relations, repo) : [],
)

export const tree$ = combine(
  {
    root: root$,
    nodes: checkupsService.nodes$,
    relations: checkupsService.relations$,
  },
  ({ root, nodes, relations }): Item[] => {
    function createNode({
      node,
      path,
    }: {
      node: {
        id: number
        type: n.NodeType
      }
      path: number
    }): null | Item {
      const recursive = ({ parentPath }: { parentPath: number }) => ({
        id,
        type,
      }: {
        id: number
        type: n.NodeType
      }): null | Item => {
        const node = n.Node.getByRef(nodes, { id, type })
        const nestingLevel = parentPath + 1

        if (!node) {
          return null
        }

        switch (node.type) {
          case 'CheckupRepoFolderNode': {
            return {
              type: 'Category',
              id,
              title: node.name,
              total: node.total,
              children: getChildren(nodes, relations, node)
                .map(node => recursive({ parentPath: nestingLevel })(node))
                .filter(isNotEmpty),
              nestingLevel,
              projectUrn: node.projectUrn,
            }
          }

          case 'CheckupNode':
            return {
              type: 'Entity',
              id,
              title: node.name,
              version: node.version,
              nestingLevel,
              readOnly: node.readOnly,
              owner: node.owner,
              projectUrn: node.projectUrn,
            }

          default:
            return null
        }
      }
      return recursive({ parentPath: path })(node)
    }

    return root.map(node => createNode({ node, path: 1 })).filter(isNotEmpty)
  },
)
