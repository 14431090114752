import * as smApi from '@gmini/sm-api-sdk'

import { Id } from './Id'
// eslint-disable-next-line import/no-cycle
import { Node } from './Node'
// eslint-disable-next-line import/no-cycle
import { CheckupRepoNode } from './CheckupRepoNode'

export interface CheckupRepoFolderNode {
  readonly type: 'CheckupRepoFolderNode'
  readonly id: Id
  readonly parentFolderId: null | Id
  readonly key: string
  readonly parentKey: null | string
  readonly name: string
  readonly total: number
  readonly offset: number
  readonly projectUrn: smApi.Urn
}

export namespace CheckupRepoFolderNode {
  export type Type = CheckupRepoFolderNode['type']
  export const TYPE: Type = 'CheckupRepoFolderNode'

  export const create = ({
    offset = 0,
    ...data
  }: Omit<CheckupRepoFolderNode, 'type' | 'offset' | 'key' | 'parentKey'> & {
    readonly offset?: number
  }): CheckupRepoFolderNode => ({
    ...data,
    offset,
    type: TYPE,
    key: getKey(data),
    parentKey: getParentKey(data),
  })

  export type Ref = Pick<CheckupRepoFolderNode, 'type' | 'id'>

  export const is = (node: Node): node is CheckupRepoFolderNode =>
    node.type === 'CheckupRepoFolderNode'

  export const getKey = ({ id }: Pick<CheckupRepoFolderNode, 'id'>): string =>
    `${TYPE}:${id}`

  export const getParentKey = ({
    parentFolderId,
    projectUrn,
  }: Pick<CheckupRepoFolderNode, 'parentFolderId' | 'projectUrn'>):
    | null
    | string =>
    parentFolderId == null
      ? CheckupRepoNode.getKey({ id: projectUrn })
      : getKey({ id: parentFolderId })

  export const getByKey = (
    nodes: Node.Map,
    key: string,
  ): null | CheckupRepoFolderNode => {
    const node = nodes[key]
    return node && is(node) ? node : null
  }
}
