import { prop } from 'ramda'

import * as smApi from '@gmini/sm-api-sdk'

import { createFetchDependenciesService } from '@gmini/common/lib/classifier-editor/DependencyTree/model'

import { sample } from 'effector'

import { currentUserClassifier$ } from '../../CurrentUserClassifier'
import { notificationService } from '../../../services/notificationService'
import { classifierService } from '../../../services/classifierService'

const entityUpdated = sample({
  clock: notificationService.message
    .filter({ fn: smApi.NotificationEvent.Update.is })
    .map(prop('payload'))
    .filter({ fn: smApi.UserClassifier.is }),
  source: currentUserClassifier$,
  fn: (currentCls, updatedCls) =>
    updatedCls.id === currentCls?.id ? updatedCls : null,
})

export const { sourceClassifiersLoaded$ } = createFetchDependenciesService({
  entity$: currentUserClassifier$,
  fetchedClassifiers: classifierService.dependencies.update.doneData.map(
    ({ BaseClassifierNode, UserClassifierNode, AssemblyClassifierNode }) => [
      ...Object.values(BaseClassifierNode),
      ...Object.values(UserClassifierNode),
      ...Object.values(AssemblyClassifierNode),
    ],
  ),
  entityUpdated,
  reset: classifierService.reset,
  nodes$: classifierService.nodes$,
})
