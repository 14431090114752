import * as t from 'io-ts'

import { Id } from '@gmini/sm-api-sdk'

import { FailedNodeType } from './FailedNodeType'

export interface AbstractFailedNode {
  itemId: Id
  itemType: FailedNodeType
}

export namespace AbstractFailedNode {
  export const io: t.Type<AbstractFailedNode> = t.type(
    {
      itemId: Id,
      itemType: FailedNodeType,
    },
    'AbstractFailedNode',
  )
}
