import * as t from 'io-ts'

import { ApiCallService } from '@gmini/api-call-service'

import { ApiDomain } from '@gmini/sm-api-sdk/lib/domain'

import { ChildrenFailedNode } from './ChildrenFailedNode'
import { FailedNodeType } from './FailedNodeType'
import { SelfFailedNode } from './SelfFailedNode'

export type FailedNode = SelfFailedNode | ChildrenFailedNode

export namespace FailedNode {
  const domain = ApiDomain.domain('FailedNode')

  export const io: t.Type<FailedNode> = t.union(
    [SelfFailedNode.io, ChildrenFailedNode.io],
    'FailedNode',
  )

  export interface GetResponse {
    items: FailedNode[]
  }

  export namespace GetResponse {
    export const io: t.Type<GetResponse> = t.type(
      {
        items: t.array(FailedNode.io),
      },
      'GetResponse',
    )
  }

  export const get = ApiCallService.create<FailedNodeReq, GetResponse>({
    name: 'get',
    parentDomain: domain,
    result: GetResponse.io,
    getKey: buildFailedNodesRequestKey,
  })
}

export interface FailedNodeReqItem {
  readonly itemId: number
  readonly itemType: FailedNodeType | 'UserClassifier'
  readonly limit: number
  readonly offset?: number
}

export function buildFailedNodesRequestKey({
  checkupId,
  checkupVersion,
  items,
}: FailedNodeReq): string {
  const itemsKey = items
    .map(({ itemId, itemType, limit, offset }) =>
      [itemType, itemId, limit, offset].filter(Boolean).join(':'),
    )
    .filter(items => items.length)
    .sort()
    .join(':')

  return `${checkupId}:${checkupVersion}:${itemsKey}`
}

export type FailedNodeReq = {
  readonly checkupId: number
  readonly checkupVersion: number
  readonly ruleId: number
  readonly items: FailedNodeReqItem[]
}
