import * as t from 'io-ts'

import * as api from '../../api'

export type ExternalEvent =
  | ExternalEvent.Create
  | ExternalEvent.Update
  | ExternalEvent.Remove

export namespace ExternalEvent {
  export const io: t.Type<ExternalEvent> = t.recursion('ExternalEvent', () =>
    t.union([Create.io, Update.io, Remove.io]),
  )

  export interface Create {
    readonly type: 'CreateEvent'
    readonly payload: Create.Payload
  }

  export namespace Create {
    export const is = (event: ExternalEvent): event is Create =>
      event.type === 'CreateEvent'

    export type Payload = api.CheckupRepoFolder | api.Checkup

    export const Payload: t.Type<Payload> = t.union([
      api.CheckupRepoFolder.io,
      api.Checkup.io,
    ])

    export const io: t.Type<Create> = Event('CreateEvent', Payload)
  }

  export interface Update {
    readonly type: 'UpdateEvent'
    readonly payload: Update.Payload
  }

  export namespace Update {
    export const is = (event: ExternalEvent): event is Update =>
      event.type === 'UpdateEvent'

    export type Payload = api.CheckupRepo | api.CheckupRepoFolder | api.Checkup

    export const Payload: t.Type<Payload> = t.union([
      api.CheckupRepo.io,
      api.CheckupRepoFolder.io,
      api.Checkup.io,
    ])

    export const io: t.Type<Update> = Event('UpdateEvent', Payload)
  }

  export interface Remove {
    readonly type: 'RemoveEvent'
    readonly payload: Remove.Payload
  }

  export namespace Remove {
    export const is = (event: ExternalEvent): event is Remove =>
      event.type === 'RemoveEvent'

    export type Payload = api.CheckupRepoFolder | api.Checkup

    export const Payload: t.Type<Payload> = t.union([
      api.CheckupRepoFolder.io,
      api.Checkup.io,
    ])

    export const io: t.Type<Remove> = Event('RemoveEvent', Payload)
  }
}

function Event<T extends string, P>(
  type: T,
  payload: t.Type<P>,
): t.Type<{
  readonly type: T
  readonly payload: P
}> {
  return t.type({
    type: t.literal(type),
    payload,
  })
}
