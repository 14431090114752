import { Tooltip, HelpRounded } from '@gmini/ui-kit'

import {
  Button,
  Text,
  Wrapper,
  Anchor,
  TooltipText,
  TooltipTitle,
  WrapperTooltip,
  IconButton,
  Separator,
  Download,
} from './RvtConfigBtn.styled'

export type RvtConfigBtnProps = {
  onClickDownload: () => void
  onClickHelp: () => void
}

export const RvtConfigBtn = ({
  onClickDownload,
  onClickHelp,
}: RvtConfigBtnProps) => (
  <Wrapper>
    <Button onClick={onClickDownload}>
      <Download color='#353B60' />
      <Text>Плагин Parameters verification</Text>
    </Button>
    <Tooltip
      placement='top'
      title={
        <WrapperTooltip>
          <TooltipTitle>
            <HelpRounded
              color='rgba(255, 255, 255, 0.25)'
              width='20px'
              height='20px'
            />
            Плагин Parameters verification
          </TooltipTitle>
          <TooltipText>
            1. Установите плагин “Плагин Parameters verification” <br />
            2. Откройте в Revit модель для проверки и перейдите на вкладку
            Надстройки
            <br />
            3. Добавьте правила и запустите проверку.
          </TooltipText>
          <Anchor
            target='_blank'
            href='https://gmini.freshdesk.com/support/solutions/articles/67000596478'
            onClick={onClickHelp}
          >
            Подробнее на портале
          </Anchor>
        </WrapperTooltip>
      }
    >
      <IconButton size='small' onClick={onClickHelp} type='circle'>
        <HelpRounded
          color='rgba(53, 59, 96, 0.25)'
          width='20px'
          height='20px'
        />
      </IconButton>
    </Tooltip>
    <Separator />
  </Wrapper>
)
