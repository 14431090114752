import { createEvent, createStore, sample } from 'effector'

import { clone, equals } from 'ramda'

import * as api from '../../../api'
import { currentRule$ } from '../../CurrentRule'
import { InputNames } from '../DifficultConditions/types'

type SaveComplexConditions = {
  ruleId: number
}

export type ComplexCondition = { initialFormula: string } & api.ComplexCondition

export const saveComplexConditions = createEvent<SaveComplexConditions>()

export const saveComplexConditionsApi = api.CheckupRule.updateConditions.createContext()

export const saveComplexConditionsPending$ = saveComplexConditionsApi.pending$

export const setInitialState = createEvent<ComplexCondition[]>()

export const addCondition = createEvent<{
  newCondition: ComplexCondition
  toBeginning?: boolean
  ruleId: number
  checkupId?: number
}>()
export const deleteCondition = createEvent<{
  idxCondition: number
  ruleId: number
  checkupId?: number
}>()
export const updateConditionField = createEvent<{
  idxCondition: number
  newFieldValue: string
  fieldName: InputNames
  ruleId: number
}>()

export type ConditionsByRuleID = Record<string, ComplexCondition[] | null>

// Мапа для условий, которые еще не сохранены
export const unsavedConditionsByRule$ = createStore<ConditionsByRuleID>({})
  .on(currentRule$, (state, result) => {
    const next = clone(state)

    if (result?.id) {
      next[result.id] = result?.complexConditions.map(({ formula, name }) => ({
        formula,
        name,
        initialFormula: formula,
      }))
    }

    return next
  })
  .on(addCondition, (state, { ruleId, newCondition, toBeginning }) => {
    const next = { ...state }

    next[ruleId] = toBeginning
      ? [newCondition, ...(next[ruleId] || [])]
      : [...(next[ruleId] || []), newCondition]

    return next
  })
  .on(deleteCondition, (state, { ruleId, idxCondition }) => {
    const next = { ...state }

    next[ruleId] = next[ruleId]?.filter((_, idx) => idx !== idxCondition) || []

    return next
  })
  .on(
    updateConditionField,
    (state, { ruleId, idxCondition, fieldName, newFieldValue }) => {
      const complexConditions = state[ruleId] || []

      return {
        ...state,
        [ruleId]: [
          ...complexConditions.slice(0, idxCondition),
          {
            ...complexConditions[idxCondition],
            [fieldName]: newFieldValue,
          },
          ...complexConditions.slice(idxCondition + 1),
        ],
      }
    },
  )

export const conditionChanged$ = sample({
  clock: unsavedConditionsByRule$,
  source: currentRule$,
  fn: (currentRule, unsavedConditionsByRule) =>
    currentRule
      ? !equals(
          currentRule?.complexConditions.map(({ name, formula }) => ({
            name,
            formula,
          })),
          unsavedConditionsByRule[currentRule.id]?.map(({ formula, name }) => ({
            formula,
            name,
          })),
        )
      : false,
})
