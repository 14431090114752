import React from 'react'

import { Tabs, Popup, IconButton } from '@gmini/ui-kit/lib'
import { RoundedCross } from '@gmini/ui-kit'

import { Title, Header, Container, DetailedHelpLink } from './HelpDialog.styled'

import { SyntaxTabContent } from './SyntaxTabContent'
import { OperationTabContent } from './OperationTabContent'
import { ExampleTabContent } from './ExampleTabContent'

export type HelpDialogProps = {
  open: boolean
  onClose: () => void
}

export const HelpDialog = ({ onClose, open }: HelpDialogProps) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0)

  const onChangeTab = React.useCallback(
    (tabIndex: number) => setActiveTabIndex(tabIndex),
    [],
  )

  return (
    <Popup open={open} onClose={onClose} width='650px' minHeight='525px'>
      <Container>
        <Header>
          <Title>Справка</Title>
          <IconButton type='circle'>
            <RoundedCross onClick={onClose} />
          </IconButton>

          <DetailedHelpLink
            target='_blank'
            rel='noreferrer'
            href='https://gmini.freshdesk.com/support/solutions/articles/67000601995-%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B-%D1%81%D0%BB%D0%BE%D0%B6%D0%BD%D1%8B%D1%85-%D1%83%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D0%B9'
          >
            Подробная справка
          </DetailedHelpLink>
        </Header>

        <Tabs
          onChangeTab={onChangeTab}
          activeTabIndex={activeTabIndex}
          tabs={[
            {
              title: 'Операции',
              content: <OperationTabContent />,
            },
            {
              title: 'Синтаксис',
              content: <SyntaxTabContent />,
            },
            { title: 'Примеры', content: <ExampleTabContent /> },
          ]}
          headerStyles={{ marginRight: '-24px' }}
        />
      </Container>
    </Popup>
  )
}
