import { ExplorerItemData } from '@gmini/ui-kit'
import { ExplorerTreeNode } from '@gmini/common/lib/Explorer'

import * as api from '../../api'

export async function fetchChildren({
  payload,
}: ExplorerItemData<ExplorerTreeNode>) {
  switch (payload.type) {
    case 'Category':
      await api.CheckupRepoFolder.Populated.fetch.defaultContext({
        id: payload.id,
      })
  }
}
