import { Id } from './Id'
// eslint-disable-next-line import/no-cycle
import { Node } from './Node'
// eslint-disable-next-line import/no-cycle
import { CheckupNode } from './CheckupNode'

export interface CheckupRuleNode {
  readonly type: 'CheckupRuleNode'
  readonly id: Id
  readonly parentCheckupId: Id
  readonly parentCheckupVersion: number
  readonly key: string
  readonly parentKey: null | string
}

export namespace CheckupRuleNode {
  export type Type = CheckupRuleNode['type']
  export const TYPE: Type = 'CheckupRuleNode'

  export type Data = Omit<CheckupRuleNode, 'type' | 'key' | 'parentKey'>

  export const create = (source: Data): CheckupRuleNode => ({
    ...source,
    type: 'CheckupRuleNode',
    key: getKey(source),
    parentKey: getParentKey(source),
  })

  export type Ref = Pick<CheckupRuleNode, 'type' | 'id'>

  export const is = (node: Node): node is CheckupRuleNode =>
    node.type === 'CheckupRuleNode'

  export const getKey = ({ id }: Pick<CheckupRuleNode, 'id'>): string =>
    `${TYPE}:${id}`

  export const getParentKey = ({
    parentCheckupId,
  }: Pick<CheckupRuleNode, 'parentCheckupId'>): string =>
    CheckupNode.getKey({ id: parentCheckupId })

  export const getByKey = (
    nodes: Node.Map,
    key: string,
  ): null | CheckupRuleNode => {
    const node = nodes[key]
    return node && is(node) ? node : null
  }
}
