import * as t from 'io-ts'

export type FailedNodeType =
  | 'UserClassifierGroup'
  | 'BimModel'
  | 'BimCategory'
  | 'BimFamily'
  | 'BimStandardSize'
  | 'BimElement'

export const FailedNodeType = t.union(
  [
    t.literal('UserClassifierGroup'),
    t.literal('BimModel'),
    t.literal('BimCategory'),
    t.literal('BimFamily'),
    t.literal('BimStandardSize'),
    t.literal('BimElement'),
  ],
  'FailedNodeType',
)
