import styled from 'styled-components'

export const Code = styled.div`
  width: max-content;
  padding: 10px 15px;
  background: rgba(53, 59, 96, 0.05);
  border-radius: 4px;
  color: #353b60;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
`

type RowTextProps = {
  isError?: boolean
}

export const RowText = styled.div`
  font-size: 12px;
  line-height: 150%;
  color: ${(props: RowTextProps) => (props.isError ? '#781B20' : '#000335')};
  letter-spacing: 0.1px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`

export const Bold = styled.span`
  word-spacing: 6px;
  font-weight: 700;
`

export const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
`

export const CloseIcon = styled.div`
  cursor: pointer;
  width: 14px;
  height: 14px;
  margin-top: auto;
  margin-bottom: auto;

  & path {
    width: 100%;
    height: 100%;
  }
`

export const Alert = styled.div`
  display: flex;
  background: rgba(225, 145, 50, 0.1);
  border-radius: 4px;
  padding: 15px 24px 15px 15px;
  margin-top: 8px;
`

export const AttentionIcon = styled.div`
  display: inline-block;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;

  & path {
    width: 100%;
    height: 100%;
  }
`

export const AlertContent = styled.div`
  margin-left: 5px;
`

export const AlertTitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #e19132;
  margin-bottom: 4px;
`

export const AlertText = styled.div`
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0.1px;
  color: #616184;
`

export const Container = styled.div`
  padding: 24px;
  position: relative;
`

type Row = {
  padding?: string
}
export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: ${(props: Row) => (props.padding ? props.padding : '8px 0')};

  & + & {
    border-top: 1px solid #e2e4e9;
  }
`

type RowLeftSide = {
  mb?: string
}

export const RowLeftSide = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  flex: 4;
  margin-bottom: ${(props: RowLeftSide) => (props?.mb ? props?.mb : 0)};
`

export const RowRightSide = styled.div`
  flex: 9;
  font-size: 12px;
`

export const RowAttention = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 10px;
`

export const RowAttentionText = styled.div`
  color: #b66f1d;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0.1px;
  margin-left: 5px;
`

export const RowWarning = styled.div`
  display: flex;
  align-items: center;
  margin: 25px 0;
`

export const WarningIcon = styled.div`
  display: inline-block;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;

  & path {
    width: 100%;
    height: 100%;
  }
`

export const RowWarningText = styled.div`
  color: #4c5ecf;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0.1px;
  margin-left: 5px;
`

export const GreenSubtitle = styled.div`
  color: #0d9966;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0.1px;
`

export const RedSubtitle = styled.div`
  color: #d9575f;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0.1px;
`

export const Subtitle = styled.div`
  color: #353b60;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0.1px;
`

export const DetailedHelpLink = styled.a`
  position: absolute;
  top: 62px;
  right: 24px;
  color: #4c5ecf;
  cursor: pointer;
  letter-spacing: 0.15px;
  font-size: 12px;
  font-weight: 700;
  line-height: 30px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
