import { memo, useEffect, useState } from 'react'
import { useStore } from 'effector-react'

import { Page404 } from '@gmini/ui-kit/lib/Page404'
import { ReadonlyModeProvider } from '@gmini/common'
import * as smApi from '@gmini/sm-api-sdk'

import * as api from '../../api'

import { checkupsService } from '../../services/checkupsService'
import { useCurrentCheckupParams } from '../CurrentCheckup'

import { seoEventManager } from '../../config'

import { CheckupEditorPage } from './CheckupEditorPage'

api.Checkup.getMostRecent.defaultContext.doneData.watch(({ classifierId }) => {
  smApi.UserClassifier.getMostRecent.defaultContext.submit({
    id: classifierId,
  })
})

export const CheckupEditorPageWrap = memo(() => {
  const currentCheckup = useStore(checkupsService.checkup.currentCheckup$)
  const readonlyMode = !!currentCheckup?.readOnly
  const params = useCurrentCheckupParams()
  const [resourceNotFound, setResourceNotFound] = useState(false)
  const [isOpenEventSended, setIsOpenEventSended] = useState(false)

  useEffect(() => {
    if (currentCheckup && !isOpenEventSended) {
      seoEventManager.push({
        event: 'Gtech_Checkup_CheckupOpen',
        payload: {
          checkupId: currentCheckup?.id,
        },
      })
      setIsOpenEventSended(true)
    }
  }, [currentCheckup, isOpenEventSended])

  useEffect(() => {
    if (currentCheckup) {
      smApi.UserClassifier.fetchIsUserClassifierLocked.defaultContext.submit({
        id: currentCheckup.classifierId,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCheckup?.classifierId])

  useEffect(() => {
    smApi.Project.fetchList.defaultContext.submit()
    if (params) {
      api.Checkup.getMostRecent.defaultContext.submit({ id: params.id })
    }
  }, [params])

  useEffect(() => {
    const subscription = api.Checkup.getMostRecent.defaultContext.failData.watch(
      ({ res }) => {
        const { errorCode } = res.data.data
        if (errorCode === 'RESOURCE_NOT_FOUND') {
          setResourceNotFound(true)
        }
      },
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [params])

  if (resourceNotFound) {
    return <Page404 />
  }

  if (!params || !currentCheckup) {
    return null
  }

  return (
    <ReadonlyModeProvider
      value={{
        enabled: readonlyMode,
        description: readonlyMode
          ? 'Проверка находится в режиме просмотра'
          : '',
      }}
    >
      <CheckupEditorPage
        isCurrentVersion={true}
        currentCheckup={currentCheckup}
      />
    </ReadonlyModeProvider>
  )
})
CheckupEditorPageWrap.displayName = 'CheckupEditorPageWrap'
