import {
  Row,
  RowRightSide,
  RowText,
  RedSubtitle,
  GreenSubtitle,
  Subtitle,
  Bold,
} from './HelpDialog.styled'

export const ExampleTabContent = () => (
  <>
    <Row padding='10px 0'>
      <RowRightSide>
        <RowText>
          <GreenSubtitle>Правильно</GreenSubtitle>
        </RowText>
        <RowText>
          <Bold>НЕПУСТО</Bold>
          (Данные.ADSKЭтаж)
          <Bold> ИЛИ НЕПУСТО</Bold>
          (Идентификация.Описание)
        </RowText>
        <RowText>
          <Bold>НЕ(НЕПУСТО</Bold>
          (Данные.ADSKЭтаж))
        </RowText>
        <RowText>
          <Bold>(НЕ(</Bold>выражение))
          <Bold> ИЛИ </Bold>
          (выражение)
        </RowText>
        <RowText>
          <Bold>НЕПУСТО</Bold>(Прочее.01 этаж)
        </RowText>
      </RowRightSide>
    </Row>
    <Row padding='10px 0'>
      <RowRightSide>
        <RowText>
          <RedSubtitle>Неправильно</RedSubtitle>
        </RowText>
        <RowText isError>
          (Данные.ADSKЭтаж)<Bold> ИЛИ </Bold>(Идентификация.Описание)
        </RowText>
        <RowText isError>
          <Bold>НЕ</Bold>(Данные.ADSKЭтаж)
        </RowText>
        <RowText isError>
          <Bold>НЕ</Bold>(выражение)<Bold> ИЛИ </Bold>(выражение)
        </RowText>
        <RowText isError>
          <Bold>непусто</Bold>(Прочее.01 этаж)
        </RowText>
      </RowRightSide>
    </Row>
    <Row padding='10px 0'>
      <RowRightSide>
        <RowText>
          <Subtitle>Пример сложной формулы:</Subtitle>
        </RowText>
        <RowText>
          (<Bold>НЕПУСТО</Bold>(Высота)<Bold> ИЛИ </Bold>(<Bold>НЕПУСТО</Bold>
          (Ширина)<Bold> И НЕПУСТО</Bold>(Глубина)))
          <Bold> И ((НЕ(НЕПУСТО</Bold>(Материал))))
        </RowText>
      </RowRightSide>
    </Row>
  </>
)
