import * as t from 'io-ts'

import { ApiCallService } from '@gmini/api-call-service'

import {
  Version,
  VersionData,
  VersionDates,
  Versions,
  Id,
} from '@gmini/sm-api-sdk'
import { ApiDomain } from '@gmini/sm-api-sdk/lib/domain'

import { ModelElementStatus } from '@gmini/sm-api-sdk/lib/ForgeViewerRepo/ModelElementStatus'

import { createPredicate } from './../createPredicate'

// eslint-disable-next-line import/no-cycle
import { CheckupRule } from './CheckupRule'

export interface Checkup {
  readonly type: 'Checkup'
  readonly id: Id
  readonly version: Version
  readonly parentFolderId: null | number
  readonly name: string
  readonly rules: readonly CheckupRule[]
  readonly classifierId: Id
  readonly classifierVersion: Version
  readonly readOnly: boolean
  readonly allowDownloadReport: boolean
  readonly owner: string | null
  readonly versionNameOwner: string | null
  readonly versionCreatedDate: string
  readonly versionName: string | null
  readonly projectUrn: string
}

export namespace Checkup {
  const domain = ApiDomain.domain('Checkup')

  export const is = createPredicate<Checkup>('Checkup')

  export const io: t.Type<Checkup> = t.type(
    {
      type: t.literal('Checkup'),
      id: Id,
      version: Version,
      classifierId: Id,
      classifierVersion: Version,
      parentFolderId: t.union([t.null, Id]),
      name: t.string,
      rules: t.readonlyArray(CheckupRule.io),
      readOnly: t.boolean,
      allowDownloadReport: t.boolean,
      owner: t.union([t.null, t.string]),
      versionNameOwner: t.union([t.null, t.string]),
      versionCreatedDate: t.string,
      versionName: t.union([t.null, t.string]),
      projectUrn: t.string,
    },
    'Checkup',
  )

  export interface CheckupStatus {
    readonly type: 'CheckupStatus'
    readonly id: Id
    readonly version: Version
    readonly status: Status
  }
  export type Status = t.TypeOf<typeof Status>

  export const Status = t.union([
    t.literal('NotStarted'),
    t.literal('Started'),
    t.literal('InProgress'),
    t.literal('Finished'),
    t.literal('Error'),
  ])

  export namespace CheckupStatus {
    export const io: t.Type<CheckupStatus> = t.type(
      {
        id: Id,
        version: Version,
        type: t.literal('CheckupStatus'),
        status: Status,
      },
      'CheckupStatus',
    )
  }

  export const create = ApiCallService.create<
    {
      readonly parentFolderId?: null | Id
      readonly name: string
      readonly projectUrn: string
    },
    Checkup
  >({
    name: 'create',
    parentDomain: domain,
    result: Checkup.io,
    getKey: ['name', 'parentFolderId'],
  })

  export const copy = ApiCallService.create<
    {
      readonly id: Id
      readonly version: Version
      readonly name: string
    },
    Checkup
  >({
    name: 'copy',
    parentDomain: domain,
    result: Checkup.io,
    getKey: ['name', 'id'],
  })

  export const rename = ApiCallService.create<
    {
      readonly id: Id
      readonly version: Version
      readonly parentFolderId: null | Id
      readonly name: string
    },
    Checkup
  >({
    name: 'rename',
    parentDomain: domain,
    result: Checkup.io,
    getKey: ['id', 'parentFolderId', 'name'],
  })

  export const move = ApiCallService.create<
    {
      readonly id: Id
      readonly version: Version
      readonly parentFolderId: null | Id
    },
    Checkup
  >({
    name: 'move',
    parentDomain: domain,
    result: Checkup.io,
    getKey: ['id', 'parentFolderId'],
  })

  export const remove = ApiCallService.create<{ readonly id: Id }, Checkup>({
    name: 'remove',
    parentDomain: domain,
    result: Checkup.io,
    getKey: ['id'],
  })

  export const getMostRecent = ApiCallService.create<
    { readonly id: Id },
    Checkup
  >({
    name: 'getMostRecent',
    parentDomain: domain,
    result: Checkup.io,
    getKey: ['id'],
  })

  export const getMostRecentSilent = ApiCallService.create<
    { readonly id: Id },
    Checkup
  >({
    name: 'getMostRecentSilent',
    parentDomain: domain,
    result: Checkup.io,
    getKey: ['id'],
  })

  export const start = ApiCallService.create<
    {
      readonly checkupId: number
      readonly checkupVersion: Version
    },
    unknown
  >({
    name: 'start',
    parentDomain: domain,
    result: t.unknown,
    getKey: ['checkupId', 'checkupVersion'],
  })

  export const fetchStatus = ApiCallService.create<
    {
      readonly checkupId: number
      readonly checkupVersion: Version
    },
    CheckupStatus
  >({
    name: 'fetchStatus',
    parentDomain: domain,
    result: CheckupStatus.io,
    getKey: ['checkupId', 'checkupVersion'],
  })

  export const fetch = ApiCallService.create<
    {
      readonly checkupId: number
      readonly checkupVersion: Version
    },
    Checkup
  >({
    name: 'fetch',
    parentDomain: domain,
    result: Checkup.io,
    getKey: ['checkupId', 'checkupVersion'],
  })

  export const checkVersion = ApiCallService.create<
    {
      readonly checkupId: number
      readonly checkupVersion: Version
    },
    boolean
  >({
    name: 'checkVersion',
    parentDomain: domain,
    result: t.boolean,
    getKey: ['checkupId', 'checkupVersion'],
  })

  export const modelElementCheckupStatus = ApiCallService.create<
    {
      readonly checkupId: Id
      readonly checkupVersion: Version
      readonly modelId: Id
    },
    ModelElementStatus
  >({
    name: 'modelElementCheckupStatus',
    parentDomain: domain,
    result: ModelElementStatus.io,
    getKey: ['checkupId', 'checkupVersion', 'modelId'],
  })

  export const fetchVersionDates = ApiCallService.create<
    {
      readonly checkupId: Id
    },
    VersionDates
  >({
    name: 'modelElementEstimationStatus',
    parentDomain: domain,
    result: VersionDates.io,
    getKey: ['checkupId'],
  })

  export const fetchVersionByDate = ApiCallService.create<
    {
      readonly checkupId: Id
      readonly versionDate: string
    },
    Versions
  >({
    name: 'modelElementEstimationStatus',
    parentDomain: domain,
    result: Versions.io,
    getKey: ['checkupId'],
  })

  export const renameVersion = ApiCallService.create<
    {
      readonly checkupId: Id
      readonly checkupVersion: Version
      readonly versionName: string
    },
    VersionData
  >({
    name: 'renameVersion',
    parentDomain: domain,
    result: VersionData.io,
    getKey: ['checkupId', 'checkupVersion', 'versionName'],
  })

  export const removeVersionName = ApiCallService.create<
    {
      readonly checkupId: Id
      readonly checkupVersion: Version
    },
    VersionData
  >({
    name: 'removeVersionName',
    parentDomain: domain,
    result: VersionData.io,
    getKey: ['checkupId', 'checkupVersion'],
  })

  export const fetchNamedVersions = ApiCallService.create<
    {
      readonly checkupId: Id
    },
    Versions
  >({
    name: 'fetchNamedVersions',
    parentDomain: domain,
    result: Versions.io,
    getKey: ['checkupId'],
  })
}
