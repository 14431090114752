import { createEvent, createStore, Event, Store } from 'effector'

import * as api from '../../api'

export interface FailedNodeService {
  current$: Store<CurrentFailedNodesState>
  resetCurrent: Event<void>
}

export type CurrentFailedNodesState = {
  [ruleId: number]:
    | {
        [itemTypePlusId: string]: api.FailedNode | undefined
      }
    | undefined
}

export function createFailedNodeService({
  reset,
}: {
  reset: Event<void>
}): FailedNodeService {
  const resetCurrent = createEvent()
  const current$ = createStore<CurrentFailedNodesState>({})
    .on(
      api.FailedNode.get.defaultContext.done,
      (state, { result: { items }, params: { ruleId } }) => {
        const byRule = state[ruleId] || {}
        const nextByRule = items.reduce(
          (acc, item) => {
            acc[
              buildFailedNodeKey({ type: item.itemType, id: item.itemId })
            ] = item
            return acc
          },
          { ...byRule },
        )

        return { ...state, [ruleId]: nextByRule }
      },
    )
    .reset(reset)
    .reset(resetCurrent)

  return {
    current$,
    resetCurrent,
  }
}

export function buildFailedNodeKey({
  id,
  type,
}: {
  type: api.FailedNodeType
  id: number
}): string {
  return type + id
}
