import { map, pipe, prop } from 'ramda'
import { Domain, Event, forward, merge, Store } from 'effector'
import { createGate, Gate } from 'effector-react'

import {
  BadResult,
  IOTSErrorResult,
  isIOTSErrorResult,
  isJsendBadResultError,
  isJsendBadResultFail,
  JsendResultError,
  JsendResultFail,
} from '@gmini/api-call-service'

import { NormalizedTree, Index, Relations } from '@gmini/normalized-tree'
import { createChildDomain } from '@gmini/utils'

import { PropertyStoreService, createPropertyStoreService } from '@gmini/common'

import * as smApi from '@gmini/sm-api-sdk'

import * as api from '../../api'

import * as n from '../Node'

import {
  CheckupRuleService,
  createCheckupRuleService,
} from './CheckupRuleService'
import {
  CheckupStoreService,
  createCheckupStoreService,
  buildCheckupStatusKey,
} from './CheckupStoreService'

import { createFailedNodeService, FailedNodeService } from './FailedNodeService'

export interface CheckupsService {
  readonly Gate: Gate<{
    readonly [key: string]: never
  }>

  readonly nodes$: Store<Index<n.Node>>
  readonly relations$: Store<Relations>

  readonly badResult: Event<BadResult>
  readonly fail: Event<JsendResultFail>
  readonly error: Event<JsendResultError>

  readonly IOTSError: Event<IOTSErrorResult>

  readonly notification: Event<api.NotificationEvent>

  readonly reset: Event<void>

  readonly checkup: CheckupStoreService
  readonly checkupRule: CheckupRuleService
  readonly property: PropertyStoreService
  readonly failedNode: FailedNodeService
}

export function createChecksService(options: {
  name?: string
  parentDomain?: Domain
}): CheckupsService {
  const domain = createChildDomain(options.parentDomain, 'CheckupsService')
  const reset = domain.event<void>('reset')

  const Gate = createGate<{ readonly [key: string]: never }>({
    name: 'Mount',
    domain,
  })

  forward({ from: Gate.close, to: reset })

  const notification = domain.event<api.NotificationEvent>('notification')

  const created = notification
    .filter({ fn: api.NotificationEvent.Create.is })
    .map(prop('payload'))

  const updated = notification
    .filter({ fn: api.NotificationEvent.Update.is })
    .map(prop('payload'))

  const removed = notification
    .filter({ fn: api.NotificationEvent.Remove.is })
    .map(prop('payload'))

  const checkupStatusChanged = notification
    .filter({ fn: api.NotificationEvent.CheckupStatusChange.is })
    .map(prop('payload'))

  const elementPropertiesListEvent = smApi.UserClassifierGroup.Property.List.fetchElementProperties.done.map(
    ({ params: { classifierId, groupId }, result: { elementProperties } }) => ({
      properties: elementProperties,
      key: generateCheckupRuleKey({ classifierId, groupId }),
    }),
  )

  const elementApplyPropertiesEvent = api.Checkup.getMostRecent.defaultContext.doneData.map(
    ({ rules }) =>
      rules.reduce(
        (acc, rule) => [...acc, ...rule.conditionsElementProperties],
        [] as smApi.UserClassifierGroup.Property[],
      ),
  )

  const checkup = createCheckupStoreService({ updatedEvent: updated })

  const property = createPropertyStoreService({
    getPropertiesListEvent: elementPropertiesListEvent,
    applyPropertiesEvent: elementApplyPropertiesEvent,
  })

  const checkupRule = createCheckupRuleService()

  const failedNode = createFailedNodeService({
    reset,
  })

  checkupRule.rule$
    // Обновляем, если пришло ws уведомление
    .on(updated.filter({ fn: api.CheckupRule.is }), (state, rule) => ({
      ...state,
      [rule.id]: rule,
    }))
    // Обновляем, если создан новый экземпляр
    .on(api.CheckupRule.create.doneData, (state, rule) => ({
      ...state,
      [rule.id]: rule,
    }))

  checkup.checkupStatus$.on(
    checkupStatusChanged,
    (data, { version, id, status }) => ({
      ...data,
      [buildCheckupStatusKey({
        checkupId: id,
        checkupVersion: version,
      })]: status,
    }),
  )

  const badResult = merge([
    api.CheckupRepoFolder.create.failData,
    api.CheckupRepoFolder.rename.failData,
    api.CheckupRepoFolder.move.failData,
    api.CheckupRepoFolder.remove.failData,

    api.Checkup.create.failData,
    api.Checkup.copy.failData,
    api.Checkup.rename.failData,
    api.Checkup.move.failData,
    api.Checkup.remove.failData,
    api.Checkup.getMostRecent.failData,
    api.Checkup.start.failData,
    api.Checkup.fetchStatus.failData,
    api.Checkup.renameVersion.failData,
    api.Checkup.removeVersionName.failData,
    api.Checkup.fetchNamedVersions.failData,
    api.Checkup.fetchVersionByDate.failData,
    api.Checkup.fetchVersionDates.failData,

    api.CheckupRepo.Populated.fetch.failData,
    // api.Property.List.fetchByRule.failData, // договорились убрать пока не разделим на 2 эндпоинта
    // api.CheckupRule.fetchStatus.failData,
    api.CheckupRule.updateConditions.failData,

    api.FailedNode.get.failData,
  ])

  const fail = badResult.filter({ fn: isJsendBadResultFail })
  const error = badResult.filter({ fn: isJsendBadResultError })

  const IOTSError: Event<IOTSErrorResult> = badResult.filter({
    fn: isIOTSErrorResult,
  })

  const getCheckupRepo = api.CheckupRepo.Populated.fetch.done.map(
    ({ params, result }) => ({ ...result, id: params.projectUrn }),
  )

  const getRepoOrFolder = merge([
    getCheckupRepo,
    api.CheckupRepoFolder.Populated.fetch.doneData,
    api.CheckupRepoFolder.Populated.fetchSilent.doneData,
  ])

  const normalizedTree = new NormalizedTree<n.Node>()

  normalizedTree.index$
    .on(
      getCheckupRepo.map(n.CheckupRepoNode.create),
      updateNode(['name', 'total', 'offset']),
    )
    .on(
      updated.filter({ fn: api.CheckupRepo.is }).map(n.CheckupRepoNode.create),
      updateNode(['name', 'total']),
    )
    .on(
      getRepoOrFolder.map(
        pipe(
          prop('children'),
          filter(api.Checkup.is),
          map(n.CheckupNode.create),
        ),
      ),
      updateNodes(['parentFolderId', 'parentKey', 'name']),
    )
    .on(
      getRepoOrFolder.map(
        pipe(
          prop('children'),
          filter(api.CheckupRepoFolder.is),
          map(n.CheckupRepoFolderNode.create),
        ),
      ),
      updateNodes(['parentFolderId', 'parentKey', 'name', 'total']),
    )
    .on(
      api.CheckupRepoFolder.create.doneData.map(n.CheckupRepoFolderNode.create),
      updateNode(['parentFolderId', 'parentKey', 'name', 'total']),
    )
    .on(
      created
        .filter({ fn: api.CheckupRepoFolder.is })
        .map(n.CheckupRepoFolderNode.create),
      updateNode(['parentFolderId', 'parentKey', 'name', 'total']),
    )
    .on(
      api.CheckupRepoFolder.Populated.fetch.doneData.map(
        n.CheckupRepoFolderNode.create,
      ),
      updateNode(['parentFolderId', 'parentKey', 'name', 'total', 'offset']),
    )
    .on(
      api.CheckupRepoFolder.Populated.fetchSilent.doneData.map(
        n.CheckupRepoFolderNode.create,
      ),
      updateNode(['parentFolderId', 'parentKey', 'name', 'total', 'offset']),
    )
    .on(
      api.CheckupRepoFolder.rename.doneData.map(n.CheckupRepoFolderNode.create),
      updateNode(['name']),
    )
    .on(
      api.CheckupRepoFolder.move.doneData.map(n.CheckupRepoFolderNode.create),
      updateNode(['parentFolderId', 'parentKey']),
    )
    .on(
      updated
        .filter({ fn: api.CheckupRepoFolder.is })
        .map(n.CheckupRepoFolderNode.create),
      updateNode(['parentFolderId', 'parentKey', 'name', 'total']),
    )
    .on(
      api.CheckupRepoFolder.remove.doneData.map(n.CheckupRepoFolderNode.create),
      removeNode,
    )
    .on(
      removed
        .filter({ fn: api.CheckupRepoFolder.is })
        .map(n.CheckupRepoFolderNode.create),
      removeNode,
    )
    .on(
      api.Checkup.create.doneData.map(n.CheckupNode.create),
      updateNode(['parentFolderId', 'parentKey', 'name', 'rules']),
    )
    .on(
      api.Checkup.copy.doneData.map(n.CheckupNode.create),
      updateNode(['parentFolderId', 'parentKey', 'name', 'rules']),
    )
    .on(
      created.filter({ fn: api.Checkup.is }).map(n.CheckupNode.create),
      updateNode(['parentFolderId', 'parentKey', 'name', 'rules']),
    )
    .on(
      api.Checkup.getMostRecent.doneData.map(n.CheckupNode.create),
      updateNode(['parentFolderId', 'parentKey', 'name', 'rules', 'version']),
    )
    .on(
      api.Checkup.rename.doneData.map(n.CheckupNode.create),
      updateNode(['name', 'version']),
    )
    .on(
      api.Checkup.move.doneData.map(n.CheckupNode.create),
      updateNode(['parentFolderId', 'parentKey', 'version']),
    )
    .on(
      updated.filter({ fn: api.Checkup.is }).map(n.CheckupNode.create),
      updateNode(['parentFolderId', 'parentKey', 'name', 'rules', 'version']),
    )
    .on(api.Checkup.remove.doneData.map(n.CheckupNode.create), removeNode)
    .on(
      removed.filter({ fn: api.Checkup.is }).map(n.CheckupNode.create),
      removeNode,
    )
    .reset(reset)

  return {
    Gate,

    nodes$: normalizedTree.index$,
    relations$: normalizedTree.relations$,

    checkup,

    checkupRule,
    property,

    failedNode,

    notification,

    badResult,
    fail,
    error,
    IOTSError,

    reset,
  }
}

export type MutableRelations = Record<string, undefined | string[]>

// TODO возможно пригодится
// type Mutable<T extends Readonly<Record<string | number | symbol, unknown>>> = {
//   [K in keyof T]: T[K]
// }

function filter<T, R extends T>(
  predicate: (value: T) => value is R,
): (items: readonly T[]) => readonly R[] {
  return (items: readonly T[]): readonly R[] => items.filter(predicate)
}

function setNode(nodes: Index<n.Node>, newNode: n.Node): Index<n.Node> {
  const key = n.Node.getKey(newNode)
  const oldNode = nodes[key]
  if (newNode === oldNode) {
    return nodes
  }
  return { ...nodes, [key]: newNode }
}

const updateNode = <N extends n.Node>(
  properties: ReadonlyArray<Exclude<keyof N, 'type' | 'id'>> = [],
) => (nodes: Index<n.Node>, newNode: N): Index<n.Node> => {
  const oldNode = n.Node.getByRef(nodes, newNode) as null | N

  if (!oldNode) {
    return setNode(nodes, newNode)
  }

  const changedProperties = properties.filter(
    property => oldNode[property] !== newNode[property],
  )

  if (!changedProperties.length) {
    return nodes
  }

  return setNode(
    nodes,
    changedProperties.reduce<N>(
      (acc, property) => {
        acc[property] = newNode[property]
        return acc
      },
      { ...oldNode } as N,
    ),
  )
}

const updateNodes = <N extends n.Node>(
  properties?: ReadonlyArray<Exclude<keyof N, 'type' | 'id'>>,
) => (nodes: Index<n.Node>, newNodes: readonly N[]): Index<n.Node> =>
  newNodes.reduce(updateNode(properties), nodes)

function removeNode(nodes: Index<n.Node>, node: n.Node): Index<n.Node> {
  const key = n.Node.getKey(node)

  if (!nodes[key]) {
    return nodes
  }

  const newNodes = { ...nodes }
  delete newNodes[key]
  return newNodes
}

export function getChildren(
  nodes: Index<n.Node>,
  relations: Relations,
  ref: n.CheckupRepoNode.Ref | n.CheckupRepoFolderNode.Ref,
): ReadonlyArray<n.CheckupRepoFolderNode | n.CheckupNode> {
  const key = n.Node.getKey(ref)
  const childrenKeys = relations[key]

  if (!childrenKeys) {
    return []
  }

  return childrenKeys.reduce((acc, key) => {
    const node = nodes[key] as
      | undefined
      | n.CheckupRepoFolderNode
      | n.CheckupNode
    if (node) {
      acc.push(node)
    }
    return acc
  }, [] as Array<n.CheckupRepoFolderNode | n.CheckupNode>)
}

export const generateCheckupRuleKey = ({
  classifierId,
  groupId,
}: {
  classifierId: number
  groupId: number
}) => `${classifierId}:${groupId}` // TODO перенести в CheckupRuleService
