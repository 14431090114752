import styled, { css } from 'styled-components'

import {
  IconButton as IconButtonInit,
  Download as DownloadInit,
} from '@gmini/ui-kit'

export const Text = styled.div`
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #353b60;
  margin: 0 4px 0 3px;
`

export const IconButton = styled(IconButtonInit)``

export const Download = styled(DownloadInit)``

type ButtonProps = {
  disabled?: boolean
}

export const Button = styled.div`
  display: flex;
  align-items: center;
  color: #353b60;
  cursor: pointer;
  user-select: none;
  margin-right: 4px;
  border-bottom: 1px solid transparent;
  padding-bottom: 1px;
  white-space: nowrap;

  &:hover {
    border-bottom: 1px solid #353b60;
  }

  &:active {
    opacity: 0.5;
  }

  ${(props: ButtonProps) =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: pointer;
      color: rgba(53, 59, 96, 0.2);

      path {
        fill: rgba(53, 59, 96, 0.2);
      }
    `}

  ${Download} {
    min-width: 24px;
  }
`

export const WrapperTooltip = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px 0;
  padding: 8px 12px 12px 12px;
`

export const TooltipTitle = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 4px;
`

export const TooltipText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  padding-left: 4px;
`

export const Anchor = styled.a`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #79afff;
  text-decoration-line: underline;
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Separator = styled.div`
  height: 36px;
  background: #e5e7f1;
  width: 1px;
  margin-left: 14px;
`
