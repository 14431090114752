import { memo, useCallback, useEffect, useState } from 'react'

import {
  FormulaInput,
  FormulaInputProps,
  FormulaOperator,
  Suggestion,
} from '@gmini/ui-kit/lib/FormulaInput'
import { TextField, WarningOutlined } from '@gmini/ui-kit'

import {
  deleteCondition,
  updateConditionField,
} from '../conditions/complexConditions'

import { seoEventManager } from '../../../config'

import { ConditionLayout } from './ConditionLayout'
import { Note, WarningWrapper } from './ConditionItem.styled'
import { InputNames } from './types'

export type ComplexConditionItem = {
  name: string
  formula: string
}

export type ConditionItemProps = {
  onValidate: (formula: string) => Promise<boolean> | boolean
  name: string
  formula: string
  allSuggestions: Suggestion[]
  suggestionsInFormula: Suggestion[] | undefined
  disabled: boolean
  idx: number
  widthFormulaResizableCol: number
  ruleId: number
  checkupId?: number
}

const operators: FormulaOperator[] = [
  { name: 'НЕПУСТО(' },
  { name: 'И' },
  { name: 'НЕ' },
  { name: 'ИЛИ' },
  { name: 'ХЭШКОД(' },
]

export const ConditionItem = memo<ConditionItemProps>(
  ({
    name,
    onValidate,
    formula,
    allSuggestions,
    suggestionsInFormula = [],
    disabled,
    idx,
    widthFormulaResizableCol,
    ruleId,
    checkupId,
  }) => {
    const [isValid, setIsValid] = useState(true)
    const [isHasSpecChar, setIsHasSpecChar] = useState(false)

    const onChangeName = useCallback(
      (value: string) => {
        updateConditionField({
          ruleId,
          fieldName: InputNames.name,
          newFieldValue: value,
          idxCondition: idx,
        })
      },
      [idx, ruleId],
    )

    const formulaHandler = useCallback(
      (value: string) => {
        updateConditionField({
          ruleId,
          fieldName: InputNames.formula,
          newFieldValue: value,
          idxCondition: idx,
        })
      },
      [idx, ruleId],
    )

    const validate = useCallback(async () => {
      const valid = await onValidate(formula)
      setIsValid(valid)
    }, [formula, onValidate])

    const onBlurFormula = useCallback(() => {
      validate()
    }, [validate])

    const onChangeFormula: FormulaInputProps['onChange'] = useCallback(
      ({ hasSpecialChar, nextStr }) => {
        setIsHasSpecChar(hasSpecialChar)
        const nextValue = nextStr.toUpperCase()
        formulaHandler(nextValue)
      },
      [formulaHandler],
    )

    const onClearFormula = useCallback(() => {
      formulaHandler('')
    }, [formulaHandler])

    useEffect(() => {
      validate()
    }, [validate])

    let errorText = ''
    if (isHasSpecChar) {
      errorText = `Формула не будет сохранена, т.к. вы использовали запрещенную комбинацию символов`
    } else if (!isValid && formula) {
      errorText = `Формула содержит ошибку, проверьте корректность`
    }

    const isAdaptiveModeNode = widthFormulaResizableCol <= 450

    return (
      <div>
        <ConditionLayout
          disabled={disabled}
          onRemove={() => {
            deleteCondition({
              ruleId,
              idxCondition: idx,
            })

            seoEventManager.push({
              event: 'Gtech_Checkup_CheckupConditionDelete',
              payload: {
                checkupId,
                conditionType: 'complexCondition',
              },
            })
          }}
          nameInput={
            <TextField
              placeholder='Название колонки'
              disabled={disabled}
              background='#fff'
              value={name}
              onChange={onChangeName}
            />
          }
          formulaInput={
            <FormulaInput
              sourceStr={formula}
              suggestionsInFormula={suggestionsInFormula}
              allSuggestions={allSuggestions}
              operators={operators}
              onChange={onChangeFormula}
              onBlur={onBlurFormula}
              onClear={onClearFormula}
              disabled={disabled}
              error={Boolean(errorText)}
            />
          }
          noteText={
            errorText && (
              <Note adaptiveMode={isAdaptiveModeNode}>
                <WarningWrapper adaptiveMode={isAdaptiveModeNode}>
                  <WarningOutlined />
                </WarningWrapper>
                {errorText}
              </Note>
            )
          }
        />
      </div>
    )
  },
)

ConditionItem.displayName = 'ConditionItem'
