import * as smApi from '@gmini/sm-api-sdk'
import {
  combine,
  createEvent,
  createStore,
  guard,
  merge,
  restore,
  sample,
} from 'effector'

import { isNotEmpty } from '@gmini/utils'

import { NotificationEvent } from '../../../api/Notifications'
import { notificationService } from '../../../services/notificationService'
import * as api from '../../../api'

import { selectedGroupNode$ } from '../../CurrentRule'
import { currentUserClassifier$ } from '../../CurrentUserClassifier'

const inclusionStatusEvent = notificationService.message.filter({
  fn: NotificationEvent.InclusionStatusChange.is,
})

type FetchedGroupsMap = Record<string, boolean>

const refetchProperties = createEvent<smApi.UserClassifierGroup.Property.PropertyRequest>()

const setInitialPending = createEvent<boolean>()
const initialPending$ = restore(setInitialPending, true)

const checkupStatus$ = restore(api.Checkup.fetchStatus.doneData, null)
const groups$ = restore(
  merge([api.Checkup.getMostRecent.doneData, api.Checkup.fetch.doneData])
    .map(checkup =>
      checkup.rules.map<smApi.UserClassifierGroup.Property.PropertyRequest>(
        rule => ({
          classifierId: checkup.classifierId,
          classifierVersion: checkup.classifierVersion,
          groupId: rule.groupId,
        }),
      ),
    )
    .filter({ fn: isNotEmpty }),
  null,
)

const isNotPending = initialPending$.map(pending => !pending)

const initialData = combine({
  groups: groups$,
  checkupStatus: checkupStatus$,
})

// initial request
sample({
  clock: initialData,
  source: initialPending$,
  fn: (initialPending, { checkupStatus, groups }) => ({
    initialPending,
    checkupStatus,
    groups,
  }),
}).watch(({ initialPending, checkupStatus, groups }) => {
  if (!initialPending || !checkupStatus || !groups) {
    return
  }

  if (checkupStatus.status === 'Finished') {
    Promise.all(
      groups.map(
        smApi.UserClassifierGroup.Property.List.fetchElementProperties
          .defaultContext,
      ),
    ).finally(() => setInitialPending(false))
    return
  }

  setInitialPending(false)
})

const dependencyRemoved = sample({
  clock: smApi.UserClassifier.removeDependency.doneData,
  source: selectedGroupNode$,
  fn: (group, cls) =>
    group
      ? {
          groupId: group.id,
          classifierId: cls.id,
          classifierVersion: cls.version,
        }
      : null,
}).filter({ fn: isNotEmpty })

guard({
  clock: dependencyRemoved,
  filter: isNotPending,
  target: refetchProperties,
})

const fetchedGroupsMap$ = createStore<FetchedGroupsMap>({})
  .on(
    smApi.UserClassifierGroup.Property.List.fetchElementProperties
      .defaultContext.done,
    (state, { params: { groupId } }) => {
      const newState = { ...state }
      newState[groupId] = true
      return newState
    },
  )
  .reset(
    merge([
      inclusionStatusEvent,
      smApi.UserClassifier.removeDependency.doneData,
    ]),
  )

sample({
  clock: merge([selectedGroupNode$.updates, inclusionStatusEvent]),
  source: combine({
    selectedGroup: selectedGroupNode$,
    fetchedGroupsMap: fetchedGroupsMap$,
    currentClassifier: currentUserClassifier$,
  }),
}).watch(({ fetchedGroupsMap, selectedGroup, currentClassifier }) => {
  if (!fetchedGroupsMap || !selectedGroup || !currentClassifier) {
    return
  }

  if (selectedGroup && !fetchedGroupsMap[selectedGroup?.id]) {
    refetchProperties({
      groupId: selectedGroup.id,
      classifierId: currentClassifier.id,
      classifierVersion: currentClassifier.version,
    })
  }
})

guard({
  source: refetchProperties,
  filter: isNotPending,
  target:
    smApi.UserClassifierGroup.Property.List.fetchElementProperties
      .defaultContext.submit,
})
