import { combine, merge, sample } from 'effector'
import { useStore, useStoreMap } from 'effector-react'

import {
  DynamicBaseGroupNode,
  UserClassifierGroupNode,
} from '@gmini/common/lib/classifier-service/Node'

import { getNode } from '@gmini/common/lib/classifier-service'

import { findInFlatTree } from '@gmini/common/lib/classifier-editor/ClassifierTree/utils'

import { isApiFlatNode } from '@gmini/common/lib/classifier-editor/ClassifierTree/createTree'

import {
  currentGroup$,
  setCurrentGroup,
} from '@gmini/common/lib/classifier-editor/ClassifierTree/model/groupModel'

import * as smApi from '@gmini/sm-api-sdk'

import {
  apiToNodeTypeMap,
  isGroupType,
} from '@gmini/common/lib/classifier-service/adapters'

import * as api from '../../api'

import { classifierService } from '../../services/classifierService'
import { checkupsService } from '../../services/checkupsService'

import { currentCheckupId$ } from '../CurrentCheckup'
import { treeModel } from '../CheckupEditorPage/EditorTreeWrap/model'

//TODO убрать все что связанно с выбранными группами в отдельную модель

export const selectedGroupNode$ = sample({
  clock: currentGroup$,
  source: combine({
    flatTree: treeModel.flatTree$,
    nodes: classifierService.nodes$,
  }),
  fn: ({ nodes, flatTree }, currentGroup) => {
    if (currentGroup) {
      const flatNode = findInFlatTree(flatTree, currentGroup.path)
      if (flatNode && isApiFlatNode(flatNode)) {
        return getNode(nodes, flatNode.ref) as
          | UserClassifierGroupNode
          | DynamicBaseGroupNode
      }
    }
    return null
  },
})

export const currentRuleId$ = combine(
  {
    rules: checkupsService.checkupRule.rule$,
    currentCheckupId: currentCheckupId$,
    selectedGroupNode: selectedGroupNode$,
  },
  ({ rules, currentCheckupId, selectedGroupNode }) => {
    for (const key in rules) {
      if (!Object.prototype.hasOwnProperty.call(rules, key)) {
        continue
      }

      const rule = rules[key]

      if (
        selectedGroupNode?.type === 'DynamicBaseGroupNode' &&
        rule?.parentCheckupId === currentCheckupId &&
        rule.groupId === selectedGroupNode?.sourceGroupId
      ) {
        return rule.id
      }
      if (
        rule?.parentCheckupId === currentCheckupId &&
        rule.groupId === selectedGroupNode?.id
      ) {
        return rule.id
      }
    }

    return null
  },
)
//TODO refactoring rule/calculation entity service
selectedGroupNode$.updates.watch(group => {
  const ruleExists = Object.values(
    checkupsService.checkupRule.rule$.getState(),
  ).some(rule => rule?.groupId === group?.id)

  const currentCheckup = checkupsService.checkup.currentCheckup$.getState()
  if (currentCheckup?.readOnly) {
    return
  }
  if (group && !ruleExists && currentCheckup) {
    api.CheckupRule.create.defaultContext.submit({
      checkupId: currentCheckup.id,
      checkupVersion: currentCheckup.version,
      groupId: group.id,
    })
  }
})

merge([
  smApi.UserClassifier.removeAll.done.map(({ params: { items } }) =>
    items.filter(({ type }) => isGroupType(apiToNodeTypeMap[type])),
  ),
  smApi.UserClassifierGroup.remove.doneData.map(group => [group]),
]).watch(removedGroups => {
  if (
    removedGroups.some(({ id }) => id === selectedGroupNode$.getState()?.id)
  ) {
    setCurrentGroup(null)
  }
})

export const useGroupRule = (groupId: null | number): null | api.CheckupRule =>
  useStoreMap({
    store: checkupsService.checkupRule.rule$,
    keys: [groupId],
    fn: (rules, [id]) => {
      for (const key in rules) {
        if (!Object.prototype.hasOwnProperty.call(rules, key)) {
          continue
        }
        const rule = rules[key]
        if (rule?.groupId === groupId) {
          return rule
        }
      }

      return null
    },
  })

export const currentRule$ = combine(
  { id: currentRuleId$, ruleMap: checkupsService.checkupRule.rule$ },
  ({ id, ruleMap }) => ruleMap[id || 0] || null,
)
export function useSelectedGroupNode():
  | UserClassifierGroupNode
  | DynamicBaseGroupNode
  | null {
  return useStore(selectedGroupNode$)
}
