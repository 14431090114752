import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 5px;
`

export const HeaderTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: rgba(0, 3, 53, 0.87);
`

export const ConditionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 0;
`

export const AddButtonWrapper = styled.div`
  margin-left: auto;
`
