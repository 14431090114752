import * as t from 'io-ts'

import { AbstractFailedNode } from './AbstractFailedNode'
import { FailedNodeReason } from './FailedNodeReason'
import { ComplexReason } from './ComplexReason'

export interface SelfFailedNode extends AbstractFailedNode {
  type: 'SelfFailedNode'
  reasons: ReadonlyArray<FailedNodeReason | ComplexReason>
}

export namespace SelfFailedNode {
  export const io: t.Type<SelfFailedNode> = t.intersection(
    [
      AbstractFailedNode.io,
      t.type({
        type: t.literal('SelfFailedNode'),
        reasons: t.readonly(
          t.array(t.union([FailedNodeReason.io, ComplexReason.io])),
        ),
      }),
    ],
    'SelfFailedNode',
  )
}
