import { getInclusionNodeType, getNode, Nodes } from '@gmini/common'
import { FlatNode } from '@gmini/common/lib/classifier-editor/ClassifierTree/createTree'
import { isReferenceNode } from '@gmini/common/lib/classifier-service/Node'
import { combine, createEvent, Event, restore, Store } from 'effector'

import { buildFailedNodeKey } from '../../../checkups-service/CheckupsService/FailedNodeService'

import { checkupsService } from '../../../services/checkupsService'

export function createFailedNodesFilterModel({
  flatTree$,
  nodes$,
}: {
  flatTree$: Store<FlatNode[]>
  nodes$: Store<Nodes>
}): {
  filteredFlatTree$: Store<FlatNode[]>
  setFilter: Event<boolean>
  filterIsOn$: Store<boolean>
} {
  const setFilter = createEvent<boolean>()
  const filterIsOn$ = restore(setFilter, false)

  const filteredFlatTree$ = combine(
    [flatTree$, checkupsService.failedNode.current$, filterIsOn$, nodes$],
    ([flatTree, failedNodesMap, filterIsOn, nodes]) => {
      if (!filterIsOn) {
        return flatTree
      }

      const flatFailedMap = Object.values(failedNodesMap).reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (acc: any, item) => {
          Object.assign(acc, item)
          return acc
        },
        {},
      )

      return flatTree.filter(flatNode => {
        const { ref } = flatNode
        switch (ref.type) {
          case 'LoadMore':
          case 'CreateNode':
          case 'UserClassifierNode':
            return true
        }

        const node = getNode(nodes, ref)
        if (!node) {
          return true
        }

        return flatFailedMap?.[
          buildFailedNodeKey({
            id: isReferenceNode(node) ? node.element.id : node.id,
            type: getInclusionNodeType(
              isReferenceNode(node) ? node.element.type : node.type,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ) as any,
          })
        ]
      })
    },
  )

  return { filteredFlatTree$, setFilter, filterIsOn$ }
}
