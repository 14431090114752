import React from 'react'
import { createGate } from 'effector-react'
import { Redirect, useParams } from 'react-router-dom'

export interface CurrentCheckupProps {
  id: number
}

const CurrentCheckupGate = createGate<CurrentCheckupProps | null>()

export const currentCheckupId$ = CurrentCheckupGate.state.map(
  props => props?.id || null,
)

export function useCurrentCheckupParams() {
  const { id, version } = useParams<{
    readonly id?: string
    readonly version?: string
  }>()

  return React.useMemo(
    () =>
      id ? ({ id: +id, version: version ? +version : null } as const) : null,
    [id, version],
  )
}

export const CurrentCheckup = React.memo(() => {
  const params = useCurrentCheckupParams()

  if (!params?.id) {
    return <Redirect to='/' />
  }
  return <CurrentCheckupGate id={params.id} />
})

CurrentCheckup.displayName = 'CurrentCheckup'
