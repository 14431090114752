import { createEvent, createStore, sample } from 'effector'

import { isNotEmpty } from '@gmini/utils'

import * as api from '../../../api'

import { currentRule$ } from '../../CurrentRule'

export type SelectAdditionalPropertiesParams = {
  properties: number[]
  checkupId: number
  checkupVersion: number
  ruleId: number
}

const setAdditionalProperties = createEvent<number[]>()
export const selectAdditionalProperties = createEvent<SelectAdditionalPropertiesParams>()
export const selectedAdditionalProperties$ = createStore<number[]>([])
  .on(selectAdditionalProperties, (_, { properties }) => properties)
  .on(setAdditionalProperties, (_, properties) => properties)

export const saveAdditionalConditionsApi = api.CheckupRule.updateConditions.createContext()

export const selectAdditionalPropertiesPending$ =
  saveAdditionalConditionsApi.pending$

sample({
  source: currentRule$.map(rule =>
    rule?.notCalculatedElementProperties?.map(condition => condition),
  ),
  fn: conditions => (conditions?.length ? conditions.filter(isNotEmpty) : []),
  target: setAdditionalProperties,
})
