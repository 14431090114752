import { combine, restore } from 'effector'
import * as smApi from '@gmini/sm-api-sdk'

import { checkupsService } from '../../../services/checkupsService'

const projectList$ = restore(
  smApi.Project.fetchList.defaultContext.doneData.map(({ list }) => list),
  null,
)

export const currentProject$ = combine({
  projects: projectList$,
  checkup: checkupsService.checkup.currentCheckup$,
}).map(
  ({ checkup, projects }) =>
    projects?.find(project => project.urn === checkup?.projectUrn) || null,
)
