import React, { useMemo } from 'react'

import { useStore } from 'effector-react'

import { CheckCircle, Error } from '@mui/icons-material'

import { styled } from '@mui/material'

import { useSnackbar } from 'notistack'

import { FolderRoot, WithSumIcon } from '@gmini/ui-kit'

import { isNotEmpty } from '@gmini/utils'

import { getNode } from '@gmini/common/lib/classifier-service'

import {
  BimRef,
  isBimStandardSizeNode,
  isReferenceNode,
  isReferenceNodeRef,
  NodeRef,
  ReferenceNode,
  UserClassifierGroupNodeRef,
} from '@gmini/common/lib/classifier-service/Node'

import { useCtxMenuFilterHandlers } from '@gmini/common/lib/components'

import {
  isGroupType,
  nodeToApiTypeMap,
} from '@gmini/common/lib/classifier-service/adapters'

import { EditorTree } from '@gmini/common/lib/classifier-editor/ClassifierTree/EditorTree'

import { moveMultiplyFromOwn } from '@gmini/common/lib/classifier-editor/ClassifierTree/multiply/moveMultiplyFromOwn'
import { insertMultiplyFromDeps } from '@gmini/common/lib/classifier-editor/ClassifierTree/multiply/insertMultiplyFromDeps'

import {
  isGeneralGroupNode,
  SearchModel,
  getInclusionNodeType,
  ModelStoreService,
} from '@gmini/common/lib/classifier-editor'

import { getViewerRefs } from '@gmini/common/lib/classifier-editor/Common'

import {
  FlatNode,
  isApiFlatNode,
  ApiFlatNode,
} from '@gmini/common/lib/classifier-editor/ClassifierTree/createTree'

import { NodeLayout } from '@gmini/common/lib/classifier-editor/ClassifierTree/NodeLayout'

import {
  buildKeyByPath,
  findInFlatTree,
  getRefFromPathKey,
  populateReference,
} from '@gmini/common/lib/classifier-editor/ClassifierTree/utils'

import { dynamicGroupMode$ } from '@gmini/common/lib/classifier-editor/ClassifierTree/dynamicGroupMode'

import {
  buildNodeVariantsPendingKey,
  pendingMapClassifier$,
} from '@gmini/common/lib/classifier-editor/ClassifierTree/model/pendingModel'

import {
  removeAllNodes,
  removeNode,
} from '@gmini/common/lib/classifier-editor/ClassifierTree/model/removeModel'

import {
  currentGroup$,
  setCurrentGroup,
} from '@gmini/common/lib/classifier-editor/ClassifierTree/model/groupModel'

import {
  TreeLoader,
  operationsPending$,
} from '@gmini/common/lib/classifier-editor/TreeLoader'
import { validateMoveFromDeps } from '@gmini/common/lib/classifier-editor/validate/validate-move'

import * as smApi from '@gmini/sm-api-sdk'

import { buildFailedNodeKey } from '../../../checkups-service/CheckupsService/FailedNodeService'
import { CheckupEntity, findRuleByGroupId } from '../../../checkups-service'

import * as api from '../../../api'

import { currentUserClassifier$ } from '../../CurrentUserClassifier'
import { classifierService } from '../../../services/classifierService'
import { checkupsService } from '../../../services/checkupsService'
import {
  buildFailedNodeReqKey,
  oldRequestItems$,
  reasonSortByArr,
  userClassifierNodeVariantsPendingMap$,
} from '../core/failed-nodes/failed-nodes.store'
import { reasonToText } from '../core/failed-nodes/reasonToText'
import {
  dependencyCheckedItems$,
  editorCheckedModel,
} from '../core/checkedModel'

import { selectedForgeElements$ } from '../core/viewerSelection/viewerSelection'

import { searchSourceModel } from '../core/searchSourceModel'

import { filterPanelService } from '../model/filterPanelService'

import { searchModel } from '../model/searchModel'

import {
  expandModel,
  failedNodesFilterIsOn$,
  flatTree$,
  setFailedNodesFilter,
  treeModel,
} from './model'

const filteredFlatTree$ = searchModel.filterTree(treeModel.flatTree$)

export type EditorTreeWrapProps = {
  currentCheckup: CheckupEntity
  currentCheckupStatus: api.Checkup.Status | null
  searchModel: SearchModel
  selectViewerRefs: (value: Record<string, string[]>) => void
  dependenciesWithModels$: ModelStoreService['dependenciesWithModels$']
}

const FILTER_LIMIT = 5000

export const EditorTreeWrap = ({
  currentCheckup,
  currentCheckupStatus,
  searchModel,
  selectViewerRefs,
  dependenciesWithModels$,
}: EditorTreeWrapProps) => {
  const { searchMatched$, searchNodes$ } = searchModel
  const ruleMap = useStore(checkupsService.checkupRule.rule$)
  const failedNodes = useStore(checkupsService.failedNode.current$)
  const selectedPath = useStore(currentGroup$)
  const properties = useStore(checkupsService.property.byId$)
  const failedNodesFilterIsOn = useStore(failedNodesFilterIsOn$)
  const checked = useStore(editorCheckedModel.checked$)

  React.useEffect(() => {
    // Expand all BimStandardSize items when filter has been switched
    const stdSizeNodes = flatTree$
      .getState()
      .filter(
        ({ ref }) =>
          ref.type === 'BimStandardSizeNode' ||
          ref.type === 'BimStandardSizeReferenceNode',
      )
    expandModel.setMultiplyExpanded({
      keys: stdSizeNodes.map(({ path }) => buildKeyByPath(path)),
      value: false,
    })

    if (failedNodesFilterIsOn && currentCheckupStatus !== 'Finished') {
      setFailedNodesFilter(false)
    }
  }, [currentCheckupStatus, failedNodesFilterIsOn])

  const selectedForgeElements = useStore(selectedForgeElements$)

  const disabledInsertFilteredElements = (node: FlatNode) => {
    const overLimit =
      (selectedForgeElements?.reduce(
        (acc, next) => acc + next.elementForgeExternalIds.length,
        0,
      ) || 0) >= FILTER_LIMIT

    if (node.ref.type === 'UserClassifierGroupWithGroupsNode') {
      return 'Нельзя вставить элементы в папку с папками'
    } else if (overLimit) {
      return `Невозможно добавить более ${FILTER_LIMIT} элементов`
    }

    return ''
  }

  const failedByGroup: typeof failedNodes = Object.fromEntries(
    Object.entries(failedNodes)
      .map(([ruleId, itemsMap]) => {
        const rule = ruleMap[ruleId]
        if (!rule) {
          return null
        }
        const { groupId, simpleConditions, complexConditions } = rule
        if (!itemsMap) {
          return [groupId, itemsMap]
        }
        const sortedItemsMap = Object.keys(itemsMap).reduce(
          (acc, next) => {
            const failedNode = itemsMap[next]

            if (next && failedNode && failedNode.type === 'SelfFailedNode') {
              const reasons = [...failedNode.reasons]
              const simpleArr = simpleConditions.map(
                condition => condition.elementPropertyId,
              )
              const complexArr = complexConditions.map(
                condition => condition.name,
              )
              return {
                ...acc,
                [next]: {
                  ...failedNode,
                  reasons: reasonSortByArr({
                    reasons,
                    simpleArr,
                    complexArr,
                  }),
                },
              }
            }
            return { ...acc, [next]: itemsMap[next] }
          },
          {} as {
            [itemTypePlusId: string]: api.FailedNode | undefined
          },
        )
        return [groupId, sortedItemsMap]
      })
      .filter(isNotEmpty),
  )

  const currentUserClassifier = useStore(currentUserClassifier$)
  const depsCheckedList = useStore(dependencyCheckedItems$)
  const nodes = useStore(classifierService.nodes$)
  const expandedMap = useStore(expandModel.expanded$)
  const searchNodes = useStore(searchNodes$)
  const pendingMap = useStore(pendingMapClassifier$)
  const failedPendingMap = useStore(userClassifierNodeVariantsPendingMap$)
  const operationsPending = useStore(operationsPending$)

  const findAnywhere = React.useCallback(
    async (
      ref: BimRef | Pick<ReferenceNode, 'id' | 'type'>,
      path: string[],
    ) => {
      let node = getNode(nodes, ref)
      if (node && isReferenceNode(node)) {
        node = getNode(nodes, node.element)
      }

      if (!node) {
        return
      }

      searchModel.setSearchNodes({ type: 'search', nodes: [{ node, path }] })

      const viewerRefs = await getViewerRefs(node, nodes)

      if (viewerRefs) {
        selectViewerRefs(viewerRefs)
      }
    },
    [nodes, searchModel, selectViewerRefs],
  )

  const removeRef = React.useCallback(
    (ref: UserClassifierGroupNodeRef | Pick<ReferenceNode, 'type' | 'id'>) => {
      removeNode(ref, currentUserClassifier!)
    },
    [currentUserClassifier],
  )

  const deleteSelected = React.useCallback(
    (items: NodeRef[]) => {
      const { id, version } = currentUserClassifier!
      removeAllNodes({
        id,
        version,
        items: items.map(item => ({
          ...item,
          type: nodeToApiTypeMap[item.type] as
            | smApi.BimReference['type']
            | smApi.UserClassifierGroup['type'],
        })),
      })
    },
    [currentUserClassifier],
  )

  React.useEffect(() => {
    if (currentCheckupStatus === 'Finished') {
      const oldRequestItems = oldRequestItems$.getState()

      const groupMap: Record<number, api.FailedNodeReqItem[]> = {}

      const expandedKeys = Object.entries(expandedMap)
        .filter(([, value]) => value)
        .map(([key]) => key)

      const expandedNodes = expandedKeys
        .map(pathKey => {
          const path = pathKey.split(':')
          return findInFlatTree(filteredFlatTree$.getState(), path)
        })
        .filter(isNotEmpty) as ApiFlatNode[]

      expandedNodes.forEach(({ ref, path }) => {
        const rootRef = getRefFromPathKey(path[0])

        const item = {
          itemId: ref.id,
          itemType: getInclusionNodeType(ref.type),
          limit: 500, // Договорились на обсуждении что пагинация пока не будет работать
        }

        if (isReferenceNodeRef(ref)) {
          const node = getNode(nodes, ref)!
          item.itemId = node.element.id
          item.itemType = getInclusionNodeType(node.element.type)
        }

        const prev = groupMap[rootRef.id] || []
        prev.push(item)
        const rule = findRuleByGroupId(ruleMap, rootRef.id)

        const filteredItems = rule
          ? prev.filter(
              item =>
                !oldRequestItems[
                  buildFailedNodeReqKey({
                    id: item.itemId,
                    type: item.itemType,
                    ruleId: rule.id,
                  })
                ],
            )
          : []

        groupMap[rootRef.id] = filteredItems
      })

      Object.entries(groupMap).forEach(([groupId, items]) => {
        const rule = findRuleByGroupId(ruleMap, groupId)
        if (rule && items.length) {
          api.FailedNode.get.defaultContext.submit({
            checkupId: currentCheckup.id,
            checkupVersion: currentCheckup.version,
            items,
            ruleId: rule.id,
          })
        }
      })
    }
  }, [
    currentCheckup.id,
    currentCheckup.version,
    currentCheckupStatus,
    expandedMap,
    nodes,
    ruleMap,
  ])

  const onPending = React.useCallback((key: string) => !!pendingMap[key], [
    pendingMap,
  ])

  const successRootGroupIds = useMemo(() => {
    const groupIds: number[] = []

    if (currentCheckup && currentCheckupStatus === 'Finished') {
      currentCheckup.rules.forEach(ruleId => {
        const rule = ruleMap[ruleId]
        if (
          rule &&
          (rule.simpleConditions.length > 0 ||
            rule.complexConditions.length > 0)
        ) {
          groupIds.push(rule.groupId)
        }
      })
    }

    return groupIds
  }, [currentCheckup, currentCheckupStatus, ruleMap])

  const allowToCreate = React.useCallback(
    (node: FlatNode) => node.ref.type !== 'UserClassifierGroupWithElementsNode',
    [],
  )

  const isSpecialNode = React.useCallback(
    (node: FlatNode) => {
      if (isApiFlatNode(node) && isGroupType(node.ref.type)) {
        return Object.values(ruleMap).some(
          rule => rule && rule.groupId === node.ref.id,
        )
      }

      return false
    },
    [ruleMap],
  )

  const { enqueueSnackbar } = useSnackbar()

  const notify = React.useCallback(
    (reason: string) => {
      enqueueSnackbar(reason, {
        variant: 'error',
      })
    },
    [enqueueSnackbar],
  )

  const onInsertFilteredElements = React.useCallback(
    (ref: UserClassifierGroupNodeRef) => {
      if (
        selectedForgeElements?.length &&
        (ref.type === 'UserClassifierGroupWithElementsNode' ||
          ref.type === 'UserClassifierEmptyGroupNode')
      ) {
        smApi.UserClassifier.createRefsFromExternalIds.defaultContext({
          id: currentUserClassifier!.id,
          version: currentUserClassifier!.version,
          parentGroupId: ref.id,
          items: selectedForgeElements,
        })
      }
    },
    [currentUserClassifier, selectedForgeElements],
  )

  const filterHandlers = useCtxMenuFilterHandlers({
    nodes,
    filterPanelService,
    checked,
    currentEntity: currentUserClassifier,
  })

  if (!currentUserClassifier) {
    return null
  }

  return (
    <>
      <TreeLoader />
      <EditorTree
        notify={notify}
        isSpecialNode={isSpecialNode}
        dynamicMode$={dynamicGroupMode$}
        dynamicGroupsConditions={{}}
        nodes$={classifierService.nodes$}
        currentUserClassifier={currentUserClassifier}
        treeModel={{ ...treeModel, flatTree$: filteredFlatTree$ }}
        checkedModel={editorCheckedModel}
        expandModel={expandModel}
        selectedFromOtherTreeCount={depsCheckedList.length}
        onSubmitCreating={(name, { parentNodeRef }) => {
          const parentGroupId = parentNodeRef ? parentNodeRef.id : undefined

          smApi.UserClassifierGroup.create.defaultContext.submit({
            classifierId: currentUserClassifier.id,
            parentClassifierVersion: currentUserClassifier.version,
            parentGroupId,
            name,
          })
        }}
        onCancelCreating={() => {
          treeModel.setInCreateNode(null)
        }}
        setInCreateNode={n => treeModel.setInCreateNode(n)}
        onSubmitEditing={(newName, node) => {
          smApi.UserClassifierGroup.rename.defaultContext.submit({
            classifierId: currentUserClassifier.id,
            parentClassifierVersion: currentUserClassifier.version,
            parentGroupId: node.parentGroupId,
            id: node.id,
            name: newName,
          })
        }}
        onMoveItems={({ target, items }) => {
          moveMultiplyFromOwn({
            currentClassifier: currentUserClassifier,
            items,
            nodes$: classifierService.nodes$,
            target,
          })
        }}
        onInsertFromOtherTree={({ target }) => {
          insertMultiplyFromDeps({
            currentClassifier: currentUserClassifier,
            dependenciesCheckedItems: depsCheckedList,
            nextParentNode: target,
            nodes$: classifierService.nodes$,
          })
        }}
        validateInsertFromOtherTree={({ target, nestingLevel }) =>
          validateMoveFromDeps({
            items: depsCheckedList,
            nodes,
            targetNode: target,
            nestingLevel,
            dynamicMode: false,
          })
        }
        onFindAnywhere={findAnywhere}
        onDelete={removeRef}
        onDeleteSelected={deleteSelected}
        searchNodes={searchNodes}
        searchMatched$={searchMatched$}
        onPending={onPending}
        allowCreate={allowToCreate}
        selectedPath={selectedPath}
        dependenciesWithModels$={dependenciesWithModels$}
        onSearchSource={searchSourceModel.setSearchSourceData}
        hideCtxMenu={operationsPending}
        {...filterHandlers}
        disabledInsertFilteredElements={disabledInsertFilteredElements}
        allowInsertFilteredElements={() => !!selectedForgeElements?.length}
        onInsertFilteredElements={onInsertFilteredElements}
        fetchExpandedInterceptor={async ({ node, treeItem }) => {
          if (
            !isBimStandardSizeNode(node) &&
            !(
              node.type === 'ReferenceNode' &&
              node.element.type === 'BimStandardSizeNode'
            )
          ) {
            return
          }

          const trueNodeRef =
            node.type === 'ReferenceNode' ? node.element : node

          if (currentCheckupStatus === 'Finished' && failedNodesFilterIsOn) {
            const rootRef = getRefFromPathKey(treeItem.path[0])
            const rule = findRuleByGroupId(ruleMap, rootRef.id)
            if (rule) {
              const res = await api.FailedNode.get.defaultContext({
                checkupId: currentCheckup.id,
                checkupVersion: currentCheckup.version,
                items: [
                  {
                    itemId: trueNodeRef.id,
                    itemType: 'BimStandardSize',
                    limit: 500,
                  },
                ],
                ruleId: rule.id,
              })

              return {
                byNodeIds: res.items
                  .filter(({ itemType }) => itemType === 'BimElement')
                  .map(({ itemId }) => itemId),
              }
            }
          }
        }}
        renderNodeLayout={({ node, nodeLayoutProps, path }) => {
          const nextProps = {
            ...nodeLayoutProps,
          }

          const rootKey = path[0]
          const rootRef = getRefFromPathKey(rootKey)

          const parentKey = path[path.length - 2]
          const parentRef = parentKey ? getRefFromPathKey(parentKey) : null
          const parentNode = parentRef ? getNode(nodes, parentRef) : null
          const parentTreeNode =
            parentNode && isReferenceNode(parentNode)
              ? populateReference(nodes, parentNode)
              : parentNode

          // Выбираем рутовую группу
          nextProps.onClick = () => {
            nodeLayoutProps.onClick?.()
            if (selectedPath && selectedPath.path.join(':') === rootKey) {
              return
            }

            setCurrentGroup({ path: [rootKey] })
          }

          const isFailed =
            failedByGroup[rootRef.id]?.[
              buildFailedNodeKey({
                id: node.type === 'ReferenceNode' ? node.element.id : node.id,
                type: getInclusionNodeType(
                  node.type === 'ReferenceNode' ? node.element.type : node.type,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ) as any,
              })
            ]

          const isFailedPending = parentTreeNode
            ? failedPendingMap[
                buildNodeVariantsPendingKey({
                  id:
                    parentTreeNode.type === 'ReferenceNode'
                      ? parentTreeNode.element.id
                      : parentTreeNode.id,
                  type: getInclusionNodeType(
                    parentTreeNode.type === 'ReferenceNode'
                      ? parentTreeNode.element.type
                      : parentTreeNode.type,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ) as any,
                })
              ]
            : false

          const isFailedChildrenPending = parentTreeNode
            ? failedPendingMap[
                buildNodeVariantsPendingKey({
                  id: node.type === 'ReferenceNode' ? node.element.id : node.id,
                  type: getInclusionNodeType(
                    node.type === 'ReferenceNode'
                      ? node.element.type
                      : node.type,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ) as any,
                })
              ]
            : false

          if (isFailedChildrenPending) {
            nextProps.pending = true
          }

          if (isFailed) {
            nextProps.textStyle = {
              ...nextProps.textStyle,
              color: '#d32f2f',
            }
            const errIcon =
              isFailed.type === 'ChildrenFailedNode' ? (
                <DotWrap>
                  <Dot color={'#d32f2f'} />
                </DotWrap>
              ) : (
                <Error fontSize='small' color='error' />
              )

            if (isFailed.type === 'SelfFailedNode') {
              nextProps.tooltipTitle = isFailed.reasons.map(reason =>
                reasonToText(reason, properties || []),
              )
            }

            nextProps.endContent = (
              <>
                <IconWrap>{errIcon}</IconWrap>
                {nextProps.moreActionButton}
              </>
            )
          } else if (
            successRootGroupIds.includes(rootRef.id) &&
            !isFailedPending
          ) {
            nextProps.textStyle = {
              ...nextProps.textStyle,
              color: '#388e3c',
            }

            const trueNode =
              node.type === 'ReferenceNode'
                ? getNode(nodes, node.element)
                : node

            const successIcon =
              trueNode && 'total' in trueNode && trueNode.total > 0 ? (
                <DotWrap>
                  <Dot color={'#388e3c'} />
                </DotWrap>
              ) : (
                <CheckCircleIcon fontSize='small' />
              )

            nextProps.endContent = (
              <>
                <IconWrap>{successIcon} </IconWrap>
                {nextProps.moreActionButton}
              </>
            )
          }
          if (isGeneralGroupNode(node) && !node.parentGroupId) {
            nextProps.icon = <WithSumIcon icon={<FolderRoot />} />
            nextProps.onClick = () => {
              setCurrentGroup({ path })
            }
          }

          return <NodeLayout {...nextProps} />
        }}
      />
    </>
  )
}

const IconWrap = styled('div')({
  width: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const Dot = styled('div')(({ color }: { color: string }) => ({
  width: '4px',
  height: '4px',
  borderRadius: '2px',
  backgroundColor: color,
}))
const DotWrap = styled('div')({
  width: '24px',
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const CheckCircleIcon = styled(CheckCircle)({
  color: '#388e3c',
})
