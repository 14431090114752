import { memo, useEffect } from 'react'
import { useStore } from 'effector-react'

import { Redirect, useParams } from 'react-router-dom'

import { ReadonlyModeProvider } from '@gmini/common'
import * as smApi from '@gmini/sm-api-sdk'

import * as api from '../../api'

import { checkupsService } from '../../services/checkupsService'

import { checkVersion, oldVersionMode$ } from '../../services/classifierService'

import { CheckupEditorPage } from './CheckupEditorPage'

api.Checkup.fetch.defaultContext.doneData.watch(
  ({ classifierId, classifierVersion }) => {
    smApi.UserClassifier.fetch.defaultContext.submit({
      id: classifierId,
      version: classifierVersion,
    })
  },
)

export const CheckupWithVersionWrap = memo(() => {
  const { id, version } = useParams<{ id: string; version: string }>()

  const currentCheckup = useStore(checkupsService.checkup.currentCheckup$)
  const oldVersionMode = useStore(oldVersionMode$)
  const versionReqPending = useStore(checkVersion.pending)

  useEffect(() => {
    smApi.Project.fetchList.defaultContext.submit()
  }, [])

  useEffect(() => {
    if (!oldVersionMode && !versionReqPending) {
      checkVersion({
        id: +id,
        version: +version,
      })
    }

    if (oldVersionMode?.active) {
      api.Checkup.fetch.defaultContext.submit({
        checkupId: +id,
        checkupVersion: +version,
      })
    }
  }, [versionReqPending, oldVersionMode, id, version])

  const readonlyMode = !!currentCheckup?.readOnly

  if (versionReqPending) {
    return null
  }

  if (oldVersionMode && !oldVersionMode.active) {
    return <Redirect to={`/checkup/${id}`} />
  }

  if (!currentCheckup) {
    return null
  }

  return (
    <ReadonlyModeProvider
      value={{
        enabled: oldVersionMode?.active || !!currentCheckup?.readOnly,
        description: readonlyMode
          ? 'Проверка находится в режиме просмотра'
          : '',
      }}
    >
      <CheckupEditorPage
        currentCheckup={currentCheckup}
        isCurrentVersion={!oldVersionMode?.active}
      />
    </ReadonlyModeProvider>
  )
})
CheckupWithVersionWrap.displayName = 'CheckupWithVersionWrap'
