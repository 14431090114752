import * as t from 'io-ts'

import { ApiCallService } from '@gmini/api-call-service'

import { Id } from '@gmini/sm-api-sdk'

import { ApiDomain } from '@gmini/sm-api-sdk/lib/domain'

import { createPredicate } from '../createPredicate'

import { Checkup } from './Checkup'
import { CheckupRepoFolder } from './CheckupRepoFolder'

export interface CheckupRepo {
  readonly type: 'CheckupRepository'
  readonly id: Id
  readonly name: string
  readonly total: number
}

export namespace CheckupRepo {
  const domain = ApiDomain.domain('CheckupRepo')

  export const is = createPredicate<CheckupRepo>('CheckupRepository')

  export const io: t.Type<CheckupRepo> = t.type(
    {
      type: t.literal('CheckupRepository'),
      id: Id,
      name: t.string,
      total: t.number,
    },
    'CheckupRepository',
  )

  export interface Populated {
    readonly type: 'CheckupRepositoryPopulated'
    readonly id: Id
    readonly name: string
    readonly total: number
    readonly offset: number
    readonly children: ReadonlyArray<CheckupRepoFolder | Checkup>
  }

  export namespace Populated {
    export const is = createPredicate<Populated>('CheckupRepositoryPopulated')

    export const io: t.Type<Populated> = t.type(
      {
        type: t.literal('CheckupRepositoryPopulated'),
        id: Id,
        name: t.string,
        total: t.number,
        offset: t.number,
        children: t.readonlyArray(t.union([CheckupRepoFolder.io, Checkup.io])),
      },
      'CheckupRepositoryPopulated',
    )

    export const fetch = ApiCallService.create<
      { readonly projectUrn: string },
      Populated
    >({
      name: 'fetch',
      parentDomain: domain,
      result: CheckupRepo.Populated.io,
      getKey: () => 'root',
    })
  }
}
