import * as React from 'react'
import { useSnackbar } from 'notistack'
import styled from 'styled-components'

import { useDownload } from '@gmini/utils'
import { CircularProgress } from '@gmini/ui-kit/lib/CircularProgress'
import { Tooltip, Button, PlayCircleFilled, Count } from '@gmini/ui-kit'

import { goals } from '@gmini/common/lib/metrika'

import * as api from '../../../api'

import { useCheckup } from '../../CurrentCheckup/useCheckup'

import { checkupsService } from '../../../services/checkupsService'

import { checksReportsFetch } from '../../../services/checkupsServiceConnect'
import { seoEventManager } from '../../../config'

type RunCheckupProps = {
  delayedRun: boolean
  widthTreeResizableCol?: number
}

export const RunCheckup = ({
  delayedRun,
  widthTreeResizableCol,
}: RunCheckupProps) => {
  const [running, setRunning] = React.useState(false)
  const {
    pending,
    xlsxReportUrl,
    checkup,
    error,
    emptyCondition,
    allowDownloadReport,
    finished,
  } = useCheckup({
    checkupsService,
  })

  const { enqueueSnackbar } = useSnackbar()

  const startCheckup = React.useCallback(
    (checkup: { checkupId: number; checkupVersion: number }) => {
      goals.runCheckup()
      api.Checkup.start.defaultContext.submit(checkup)

      seoEventManager.push({
        event: 'Gtech_Checkup_CheckupRun',
        payload: {
          checkupId: checkup.checkupId,
        },
      })
    },
    [],
  )

  React.useEffect(() => {
    const runCheckupSubscription = api.Checkup.fetchStatus.defaultContext.doneData.watch(
      params => {
        if (params.status === 'NotStarted' && running) {
          startCheckup({
            checkupId: params?.id,
            checkupVersion: params?.version,
          })
          setRunning(false)
        }
      },
    )

    return () => {
      runCheckupSubscription.unsubscribe()
    }
  }, [checkup, startCheckup, running])

  const prepareCheckupRun = React.useCallback(() => {
    if (delayedRun) {
      setRunning(true)
      return
    }
    if (!checkup) {
      throw new Error('[RunCheckup]: Checkup is null')
    }
    startCheckup(checkup)
  }, [checkup, delayedRun, startCheckup])

  const onError = React.useCallback(() => {
    enqueueSnackbar(errorText, {
      variant: 'error',
    })
    console.error(xlsxReportUrl)
  }, [enqueueSnackbar, xlsxReportUrl])

  const { execute, pending: downloadPending } = useDownload({
    onError,
    url: xlsxReportUrl,
    fetchService: checksReportsFetch,
  })

  const onDownloadClick = React.useCallback(() => {
    execute()
    goals.downloadReportCheckup()

    seoEventManager.push({
      event: 'Gtech_Checkup_CheckupExport',
      payload: {
        checkupId: checkup?.checkupId,
      },
    })
  }, [checkup?.checkupId, execute])

  const runDisabled = React.useMemo((): string => {
    if (pending) {
      return 'Загрузка'
    } else if (error) {
      return 'Произошла ошибка'
    } else if (emptyCondition) {
      return 'Не выбрано ни одно условие проверки'
    } else if (finished) {
      return 'Проверка пройдена'
    }

    return ''
  }, [emptyCondition, error, finished, pending])

  const adaptiveMode = Number(widthTreeResizableCol) < 180
  let tooltipRunCheckup = runDisabled

  if (!tooltipRunCheckup && adaptiveMode) {
    tooltipRunCheckup = 'Запустить проверку'
  }

  return !xlsxReportUrl || !allowDownloadReport ? (
    <Tooltip title={tooltipRunCheckup}>
      <div style={{ marginLeft: 'auto' }}>
        <Button
          data-test-id='runCheckup'
          disabled={Boolean(runDisabled) || running}
          onClick={prepareCheckupRun}
          leftIcon={
            <>
              <PlayCircleFilled />
              {(running || pending) && !error && <IconSpinner size={24} />}
            </>
          }
        >
          {!adaptiveMode && 'Запустить проверку'}
        </Button>
      </div>
    </Tooltip>
  ) : (
    <Tooltip title={adaptiveMode ? 'Экспорт в xlsx' : ''}>
      <Button
        onClick={onDownloadClick}
        disabled={downloadPending || delayedRun}
        leftIcon={
          <>
            <Count />
            {downloadPending && <IconSpinner size={24} />}
          </>
        }
      >
        {!adaptiveMode && 'Экспорт в xlsx'}
      </Button>
    </Tooltip>
  )
}

const errorText = `Непредвиденная ошибка. Пожалуйста, обратитесь к разработчикам`

export const IconSpinner = styled(CircularProgress)`
  color: inherit;
  position: absolute;
  left: 6px;
  top: 0;
  bottom: 0;
  margin: auto 0;

  & svg {
    width: 24px !important;
    height: 24px !important;
  }
`
