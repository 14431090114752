import * as smApi from '@gmini/sm-api-sdk'

import { Id } from './Id'
// eslint-disable-next-line import/no-cycle
import { Node } from './Node'

export interface CheckupRepoNode {
  readonly type: 'CheckupRepoNode'
  readonly id: Id | smApi.Urn
  readonly key: string
  readonly name: string
  readonly total: number
  readonly offset: number
}

export namespace CheckupRepoNode {
  export type Type = CheckupRepoNode['type']
  export const TYPE: Type = 'CheckupRepoNode'

  export const create = ({
    offset = 0,
    ...data
  }: Omit<CheckupRepoNode, 'type' | 'offset' | 'key'> & {
    readonly offset?: number
  }): CheckupRepoNode => ({
    ...data,
    offset,
    type: TYPE,
    key: getKey(data),
  })

  export type Ref = Pick<CheckupRepoNode, 'type' | 'id'>

  export const is = (node: Node): node is CheckupRepoNode =>
    node.type === 'CheckupRepoNode'

  export const getKey = ({ id }: Pick<CheckupRepoNode, 'id'>): string =>
    `${TYPE}:${id}`

  export const getByKey = (
    nodes: Node.Map,
    key: string,
  ): null | CheckupRepoNode => {
    const node = nodes[key]
    return node && is(node) ? node : null
  }
}
