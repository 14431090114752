import * as t from 'io-ts'

import * as api from '../../api'

export type IncomingMessage = UpdateEvent | CheckupChangeStatusEvent

export const IncomingMessage: t.Type<IncomingMessage> = t.recursion(
  'IncomingMessage',
  () => t.union([UpdateEvent, CheckupChangeStatusEvent]),
)
export interface CheckupChangeStatusEvent {
  readonly type: 'CheckupChangeStatusEvent'
  readonly payload: CheckupChangeStatusEventPayload
}

export const CheckupChangeStatusEvent: t.Type<CheckupChangeStatusEvent> = t.type(
  {
    type: t.literal('CheckupChangeStatusEvent'),
    payload: t.recursion(
      'CheckupChangeStatusEventPayload',
      () => CheckupChangeStatusEventPayload,
    ),
  },
)
export type CheckupChangeStatusEventPayload = api.Checkup.CheckupStatus

export const CheckupChangeStatusEventPayload = api.Checkup.CheckupStatus.io

export interface UpdateEvent {
  readonly type: 'UpdateEvent'
  readonly payload: UpdateEventPayload
}

export type UpdateEventPayload = api.CheckupRule

export const UpdateEventPayload = api.CheckupRule.io

export const UpdateEvent: t.Type<UpdateEvent> = t.type({
  type: t.literal('UpdateEvent'),
  payload: UpdateEventPayload,
})
