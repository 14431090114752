import * as t from 'io-ts'

export interface ComplexCondition {
  name: string
  formula: string
}

export const ComplexCondition: t.Type<ComplexCondition> = t.type(
  {
    name: t.string,
    formula: t.string,
  },
  'ComplexCondition',
)
