import * as t from 'io-ts'

// eslint-disable-next-line import/no-cycle
import { SimpleReason } from './SimpleReason'

export type ComplexReasonResult =
  | 'FAILED'
  | 'INVALID_FORMULA'
  | 'HASH_CODE_NOT_EXIST'
  | 'HASH_CODE_NOT_ACTIVE'

export interface ComplexReason {
  conditionType: 'ComplexCondition'
  conditionName: string
  result: ComplexReasonResult
}

export namespace ComplexReason {
  export const io: t.Type<ComplexReason> = t.type({
    conditionType: t.literal('ComplexCondition'),
    conditionName: t.string,
    result: t.union([
      t.literal('FAILED'),
      t.literal('INVALID_FORMULA'),
      t.literal('HASH_CODE_NOT_EXIST'),
      t.literal('HASH_CODE_NOT_ACTIVE'),
    ]),
  })

  export const is = (r: SimpleReason | ComplexReason): r is ComplexReason =>
    r.conditionType === 'ComplexCondition'
}
