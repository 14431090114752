import { getNode } from '@gmini/common/lib/classifier-service'
import { isApiFlatNode, ApiFlatNode } from '@gmini/common'
import { isNotEmpty } from '@gmini/utils'
import { createEvent, sample, restore } from 'effector'

import { classifierService } from '../../../services/classifierService'

import { dependencyTreeModel } from './dependencyTreeModel'

const treeUpdated = createEvent<ApiFlatNode[]>()

dependencyTreeModel.flatTree$.watch(tree => {
  treeUpdated(tree.filter(isApiFlatNode))
})

// TODO разобраться почему нет апдейтов от стора dependencyTreeModel.flatTree$ в подписке clock(sample)
// когда source:classifierService.nodes$
// Информация будет всегда актуальной так как flatTree обновится после апдейта nodes$
// Сделано во избежания конфликта со стором tree$
const setDependencyRootNodes = sample({
  clock: treeUpdated,
  fn: flatTree =>
    flatTree
      .filter(({ path }) => path.length === 1)
      .map(({ ref }) => getNode(classifierService.nodes$.getState(), ref))
      .filter(isNotEmpty),
})

export const dependencyRootNodes$ = restore(setDependencyRootNodes, [])
