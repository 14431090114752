import { sample } from 'effector'

import { buildCheckupStatusKey } from '../../../checkups-service'

import { checkupsService } from '../../../services/checkupsService'
//TODO применить стор ко всем вычислениям текущего статуса в приложении
export const currentCheckupStatus$ = sample({
  clock: checkupsService.checkup.checkupStatus$,
  source: checkupsService.checkup.currentCheckup$,
  fn: (checkup, checkupStatus) =>
    checkup && checkupStatus
      ? checkupStatus[
          buildCheckupStatusKey({
            checkupId: checkup.id,
            checkupVersion: checkup.version,
          })
        ] || null
      : null,
})
