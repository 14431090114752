import { createSearchModel } from '@gmini/common'
import { combine } from 'effector'

import { treeModel } from '../EditorTreeWrap/model'
import { dependencyTreeModel } from '../core/dependencyTreeModel'
import { classifierService } from '../../../services/classifierService'
import { currentUserClassifier$ } from '../../CurrentUserClassifier'

const tree$ = combine(
  dependencyTreeModel.flatTree$,
  treeModel.flatTree$,
  (treeModel, dependencyTreeModel) => [...treeModel, ...dependencyTreeModel],
)

export const { Tags, searchModel } = createSearchModel({
  nodes$: classifierService.nodes$,
  currentEntity$: currentUserClassifier$,
  tree$,
  searchInDynamicGroups: true,
})
