import { createStore, merge, Store } from 'effector'

import * as api from '../../api'

export type CheckupRuleService = {
  rule$: Store<CheckupRulesStoreData>
}

export type CheckupRulesStoreData = Record<string, api.CheckupRule | undefined>

export function createCheckupRuleService(): CheckupRuleService {
  const rule$ = createStore<CheckupRulesStoreData>({}).on(
    merge([api.Checkup.getMostRecent.doneData, api.Checkup.fetch.doneData]),
    (_, p) =>
      p.rules.reduce(
        (acc, rule) => ({ ...acc, [rule.id]: rule }),
        {} as CheckupRulesStoreData,
      ),
  )

  return {
    rule$,
  }
}

export function buildRuleKey({
  checkupId,
  checkupVersion,
}: {
  checkupId: number
  checkupVersion: number
}) {
  return `${checkupId}:${checkupVersion}`
}

export function findRuleByGroupId(
  rules: CheckupRulesStoreData,
  groupId: number | string,
): api.CheckupRule | null {
  return Object.values(rules).find(r => r?.groupId === +groupId) || null
}
