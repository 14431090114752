import * as React from 'react'
import { useStore } from 'effector-react'

import { Store, Event } from 'effector'

import { BaseConditions } from '@gmini/common/lib/components'

import { useReadonlyMode } from '@gmini/common'

import * as smApi from '@gmini/sm-api-sdk'

import { checkupsService } from '../../../services/checkupsService'

import { useCurrentRule } from '../../CurrentRule/useCurrentRule'

import { useRulePropertyList } from '../properties/useRulePropertyList'

import { useCheckup } from '../../CurrentCheckup/useCheckup'

import { SelectPropertiesParams } from '../conditions/simpleConditions'

import { SelectAdditionalPropertiesParams } from '../conditions/additionalConditions'
import { seoEventManager } from '../../../config'

const emptyArray: smApi.UserClassifierGroup.Property[] = []

type BaseConditionsContainerProps = {
  widthFormulaResizableCol: number
  title: string
  selectedProperties$: Store<number[]>
  selectProperties: Event<
    SelectAdditionalPropertiesParams | SelectPropertiesParams
  >
  selectPropertiesPending$: Store<boolean>
}

export const BaseConditionsContainer = React.memo(
  ({
    widthFormulaResizableCol,
    title,
    selectedProperties$,
    selectProperties,
    selectPropertiesPending$,
  }: BaseConditionsContainerProps) => {
    const currentCheckup = useStore(checkupsService.checkup.currentCheckup$)
    const currentRule = useCurrentRule()

    const { pending: checkupPending } = useCheckup({
      checkupsService,
    })

    const selectedPropertiesIds = useStore(selectedProperties$)
    const propertiesById = useStore(checkupsService.property.byId$)

    const { propertyList, pending: listPending } = useRulePropertyList()

    const pending = useStore(selectPropertiesPending$)
    const { readonlyMode } = useReadonlyMode()

    return (
      <BaseConditions
        selectedPropertiesIds={selectedPropertiesIds}
        selectProperties={propertiesIds => {
          if (propertiesIds && currentCheckup && currentRule) {
            selectProperties({
              checkupId: currentCheckup.id,
              checkupVersion: currentCheckup.version,
              properties: propertiesIds,
              ruleId: currentRule.id,
            })

            propertiesIds
              .filter(item => !selectedPropertiesIds.includes(item))
              .forEach(() => {
                seoEventManager.push({
                  event: 'Gtech_Checkup_CheckupConditionSet',
                  payload: {
                    checkupId: currentCheckup.id,
                    conditionType: 'simpleCondition',
                  },
                })
              })

            selectedPropertiesIds
              .filter(item => !propertiesIds.includes(item))
              .forEach(() => {
                seoEventManager.push({
                  event: 'Gtech_Checkup_CheckupConditionDelete',
                  payload: {
                    checkupId: currentCheckup.id,
                    conditionType: 'simpleCondition',
                  },
                })
              })
          }
        }}
        availableProperties={propertyList || emptyArray}
        listPending={listPending}
        propertiesById={propertiesById}
        selectPending={pending}
        title={title}
        disabled={readonlyMode.enabled || checkupPending}
        widthFormulaResizableCol={widthFormulaResizableCol}
      />
    )
  },
)

BaseConditionsContainer.displayName = 'BaseConditionsContainer'
