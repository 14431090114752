import { Warning, Attention } from '@gmini/ui-kit'

import {
  Code,
  AttentionIcon,
  RowAttention,
  RowText,
  RowWarningText,
  RowWarning,
  RowAttentionText,
  WarningIcon,
  Bold,
  RowLeftSide,
  RowRightSide,
  Row,
} from './HelpDialog.styled'

export const SyntaxTabContent = () => (
  <>
    <Row padding='8px 0 40px 0'>
      <RowLeftSide mb='auto'>
        <Code>И</Code> <Code>ИЛИ</Code>
      </RowLeftSide>
      <RowRightSide>
        <div>
          <RowText>
            <Bold>НЕПУСТО</Bold>
            (имясвойства)
            <Bold> ИЛИ НЕПУСТО</Bold>
            (имясвойства)
          </RowText>
          <RowText>
            (выражение)
            <Bold> ИЛИ НЕПУСТО</Bold>
            (имя_свойства)
          </RowText>
          <RowText>
            (выражение)<Bold> ИЛИ </Bold>
            (выражение)
          </RowText>
        </div>
      </RowRightSide>
    </Row>
    <Row padding='8px 0 40px 0'>
      <RowLeftSide mb='auto'>
        <Code>НЕ(...)</Code>
      </RowLeftSide>
      <RowRightSide>
        <RowAttention>
          <AttentionIcon>
            <Attention />
          </AttentionIcon>

          <RowAttentionText>
            выражение для НЕ должно быть обязательно в скобках:
          </RowAttentionText>
        </RowAttention>
        <div>
          <RowText>
            <Bold>НЕ</Bold> (выражение)
          </RowText>
          <RowText>
            <Bold>НЕ(НЕПУСТО</Bold>
            (имя_свойства))
          </RowText>
        </div>

        <RowWarning>
          <WarningIcon>
            <Warning />
          </WarningIcon>

          <RowWarningText>
            если результат операции НЕ должен быть подставлен в И / ИЛИ, то его
            тоже нужно обрамлять скобками:
          </RowWarningText>
        </RowWarning>

        <RowText>
          <Bold>(НЕ</Bold>
          (выражение))
          <Bold> ИЛИ </Bold>
          (выражение)
        </RowText>
      </RowRightSide>
    </Row>
  </>
)
